import React, { useState } from 'react';
import { Button, Modal, Grid, Group, Space } from '@mantine/core';
import { ContractV2Type } from 'store/pricing/types';
import { DatePickerInput } from '@mantine/dates';
import showToast from 'actions/toastActions';

interface Props {
  opened: boolean;
  onClose: () => void;
  onSubmit: (contract: Partial<ContractV2Type>) => void;
}

const AddDefaultContractModal = ({ opened, onClose, onSubmit }: Props) => {
  const [contract, setContract] = useState<Partial<ContractV2Type>>({});
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    setContract({});
    onClose();
  };

  const submitForm = async () => {
    if (!contract.signed_at || !contract.expired_at) {
      return showToast('Please fill all required fields', 'error');
    }
    try {
      setLoading(true);
      await onSubmit({
        ...contract,
        signed_at: new Date(contract.signed_at),
        expired_at: new Date(contract.expired_at),
      });
      closeModal();
      showToast('Contract added successfully');
    } catch (error) {
      showToast(error.message, 'error');
    }
  };

  const updateContract = (attributeKey: keyof ContractV2Type, newValue: string) => {
    setContract({ ...contract, [attributeKey]: newValue });
  };

  return (
    <Modal title="Create Default Contract" centered opened={opened} onClose={onClose} size="lg">
      <Grid grow>
        <Grid.Col span={6}>
          <DatePickerInput
            label="Signed At"
            value={contract.signed_at ? new Date(contract.signed_at) : undefined}
            onChange={(date) => updateContract('signed_at', String(date))}
            w="100%"
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <DatePickerInput
            label="Expired At"
            value={contract.expired_at ? new Date(contract.expired_at) : undefined}
            onChange={(date) => updateContract('expired_at', String(date))}
            w="100%"
          />
        </Grid.Col>
      </Grid>
      <Space h="xl" />
      <Group justify="flex-end">
        <Button variant="outline" onClick={closeModal} disabled={loading}>
          Cancel
        </Button>
        <Button onClick={submitForm} loading={loading}>
          Save
        </Button>
      </Group>
    </Modal>
  );
};

export default AddDefaultContractModal;
