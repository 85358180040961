import React, { useEffect, useState } from 'react';
import { Group, Slider, Text } from '@mantine/core';
import { EOInferenceLayerType, EOTargetMeasurement } from 'store/eoCollections/types';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getCompactionResultsLayerByDepth, getTestResultsDcpLayerByDepth } from 'util/proMaps';

type Props = {
  availableLayers: EOInferenceLayerType[];
  availableDcpLayers: EOTargetMeasurement[];
  setGeojsonUri: (uri: string) => void;
  setDcpUri: (uri: string) => void;
  setInferenceLayer: (inferenceLayer: EOInferenceLayerType) => void;
  onChange: () => void;
};

const DEFAULT_DEPTH = 12;

export const CompactionTestResultsSlider = ({
  availableLayers,
  setGeojsonUri,
  setInferenceLayer,
  onChange,
  setDcpUri,
  availableDcpLayers,
}: Props) => {
  const [depth, setDepth] = useState(DEFAULT_DEPTH);
  const language = useBroswerLanguage();

  useEffect(() => {
    const matchedLayer = getCompactionResultsLayerByDepth(depth, availableLayers);
    const matchedDcpLayer = getTestResultsDcpLayerByDepth(depth, availableDcpLayers);

    if (matchedLayer) {
      setInferenceLayer(matchedLayer);
      setGeojsonUri(matchedLayer.geojson_uri);
    }

    if (matchedDcpLayer) {
      setDcpUri(matchedDcpLayer.geojson_uri);
    }
  }, [availableLayers, depth, setGeojsonUri, setInferenceLayer]);

  const handleChange = (newDepth: number) => {
    setDepth(newDepth);
    onChange();
  };

  return (
    <Group pr="lg" gap="lg" flex={1} maw={350}>
      <Text id="test-results-slider" style={{ justifySelf: 'flex-end' }} fw="bold">
        {getString('depth', language)}
      </Text>
      <Slider
        aria-labelledby="test-results-slider"
        min={0}
        max={18}
        value={depth}
        step={1}
        thumbSize={24}
        label={(inch) => `${inch}"`}
        style={{ flex: 1 }}
        marks={[
          { value: 0, label: getString('surface', language) },
          { value: 3, label: '3"' },
          { value: 6, label: '6"' },
          { value: 9, label: '9"' },
          { value: 12, label: '12"' },
          { value: 16, label: '16"' },
          { value: 18, label: '18"' },
        ]}
        onChange={handleChange}
      />
    </Group>
  );
};
