import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Center, Group, Loader, Stack, Text } from '@mantine/core';
import { Header } from 'common/Components/Mantine/Header';
import { RootState } from 'store';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import showToast, { type ToastType } from 'actions/toastActions';
import { CatalogType } from 'store/catalogs/types';
import { createCatalog, getAllCatalogs, getCatalog, updateCatalog } from 'store/catalogs/thunks';

import SetAttributes from './Sections/SetAttributes';
import ProductList from './Sections/ProductList/Container';

const EditCatalogContainer = () => {
  const language = useBroswerLanguage();
  const navigate = useNavigate();
  const [catalogForm, setCatalogForm] = useState<Partial<CatalogType>>({
    name: '',
    id: undefined,
    show_in_ui: true,
  });
  const [isSaving, toggleIsSaving] = useState(false);
  const dispatch = useDispatch();
  const { id: catalogId } = useParams<{
    id: string;
  }>();

  const numCatalogId = Number(catalogId);

  const { catalog, isFetching } = useSelector((state: RootState) => ({
    catalog: numCatalogId ? state.catalogs.byId[numCatalogId] : undefined,
    isFetching: state.catalogs.isFetching,
  }));

  useEffect(() => {
    if (numCatalogId) {
      dispatch(getCatalog(numCatalogId));
    }
  }, [dispatch, numCatalogId]);

  useEffect(() => {
    if (catalog) {
      setCatalogForm(catalog);
    }
  }, [catalog, isFetching]);

  const showMessage = useCallback(
    (message: string, type: ToastType) => showToast(message, type),
    [],
  );

  const saveForm = async () => {
    toggleIsSaving(true);
    try {
      if (catalogForm?.id) {
        await dispatch(updateCatalog(catalogForm.id, catalogForm));
        toggleIsSaving(false);
        dispatch(getAllCatalogs());
        navigate('/catalogs');
      } else {
        // Need to use any here, typescript can't recognize what is returned by createCatalog
        const response: any = await dispatch(createCatalog(catalogForm));
        toggleIsSaving(false);
        dispatch(getAllCatalogs());
        if (response.id) {
          navigate(`/catalog/${response.id}`);
        }
      }
    } catch (e) {
      showToast((e as Error).message, 'error');
    }
  };

  const handleOnValueChange = (
    attributeName: string,
    newValue: string | number | boolean | { [key: string]: any },
  ) => setCatalogForm((newCatalogForm) => ({ ...newCatalogForm, [attributeName]: newValue }));

  return (
    <Stack>
      <Header
        title={
          <Text
            size="xl"
            fw={500}
          >{`${getString('catalogDetails', language)} - ${catalog?.name || getString('new', language)}`}</Text>
        }
      >
        <Group justify="flex-end">
          <Button loading={isSaving} onClick={saveForm}>
            {getString('save', language)}
          </Button>
          <Button variant="outline" onClick={() => navigate(`/catalogs`)}>
            {getString('cancel', language)}
          </Button>
        </Group>
      </Header>
      {isFetching && !catalog ? (
        <Center h="60vh">
          <Loader />
        </Center>
      ) : (
        <>
          <SetAttributes catalog={catalogForm} onValueChange={handleOnValueChange} />
          <ProductList
            onError={(msg: string) => showMessage(msg, 'error')}
            isSaving={isSaving}
            catalog={catalogForm}
            toggleIsSaving={toggleIsSaving}
          />
        </>
      )}
    </Stack>
  );
};

export default EditCatalogContainer;
