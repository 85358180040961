import { PRESSURE_PANEL, PERFORMANCE_PANEL, NUTRIENT_PANEL, NITRATE_PANEL } from './products';
import { ZONE_TYPES } from './mapbox';

export const PRICING = 'pricing';
export const SKUS = 'skus';

export const PRICING_TABS = [SKUS, PRICING];

export const IS_PRO = 'is_pro';
export const IS_TILL_RX = 'is_till_rx';

export const ANALYSIS = 'analysis';
export const SAMPLING = 'sampling';
export const SCANNING = 'scanning';
export const MAP_GENERATION = 'map_generation';

export const PER_ACRE = 'per_acre';
export const PER_SAMPLE = 'per_sample';

export const PRICE_TYPES = [PER_ACRE, PER_SAMPLE];

export const skuProductTypes = [
  IS_PRO,
  IS_TILL_RX,
  PRESSURE_PANEL,
  PERFORMANCE_PANEL,
  NUTRIENT_PANEL,
  NITRATE_PANEL,
];

export const skuTypes = [ANALYSIS, SAMPLING, SCANNING, MAP_GENERATION];

export const skuSamplingTypes = [ZONE_TYPES.POLYGON, ZONE_TYPES.POINT];
