import React, { useState } from 'react';
import { type FlexProps, Flex, Group, SegmentedControl, Alert, Skeleton } from '@mantine/core';
import mapboxgl from 'mapbox-gl';

import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { CompactionTestResultsSlider } from './CompactionTestResultsSlider';
import {
  DCP_POINTS_LAYER_ID,
  tillagePolygonLayerIds,
  TILLAGE_LAYER_RX,
  TILLAGE_LAYER_TEST_RESULTS,
} from 'constants/proMaps';
import { DcpPointsToggle } from './DcpPointsToggle';
import {
  useAvailableInferenceLayers,
  useAvailableTargetMeasurementLayers,
  useSetActiveLayerSourceData,
  useToggleActivePolyLayerVisibility,
} from 'util/hooks/compactionMap';
import { RxResultsSlider } from './RxResultsSlider';
import { EOInferenceLayerType } from 'store/eoCollections/types';
import { setSourceData, toggleLayerVisibility } from 'util/mapbox';
import { DownloadScriptBtn } from 'apps/Results/common/DownloadRxScriptBtn';
import { FieldPropertiesType } from 'store/fields/types';

export type TillageLayerId = (typeof tillagePolygonLayerIds)[keyof typeof tillagePolygonLayerIds];

type Props = {
  samplingPlanId: number;
  mapHasLoaded: boolean;
  mapRef: React.MutableRefObject<mapboxgl.Map | null>;
  setInferenceLayer: (inferenceLayer: EOInferenceLayerType) => void;
  onActiveLayerChange: (layerId: TillageLayerId) => void;
  onSliderChange: () => void;
  activeTillageLayerId: TillageLayerId;
  mapDisplayTypeToggle: React.ReactNode;
  field: GeoJSON.Feature<GeoJSON.MultiPolygon, FieldPropertiesType>;
  rootFlexProps?: FlexProps;
  inlineRxResultsSlider?: boolean;
};

export const TillRxMapCtrls = ({
  samplingPlanId,
  mapHasLoaded,
  mapRef,
  setInferenceLayer,
  onActiveLayerChange,
  onSliderChange,
  activeTillageLayerId,
  mapDisplayTypeToggle,
  field,
  rootFlexProps,
  inlineRxResultsSlider,
}: Props) => {
  const language = useBroswerLanguage();
  const availableLayersQuery = useAvailableInferenceLayers(samplingPlanId);
  const availableDcpLayers = useAvailableTargetMeasurementLayers(samplingPlanId);

  const [dcpIsChecked, setDcpIsChecked] = useState(false);
  const [rxGeojsonUri, setRxGeojsonUri] = useState<string>();
  const [testResultsGeojsonUri, setTestResultsGeojsonUri] = useState<string>();
  const activeLayerIsTestResults = activeTillageLayerId === TILLAGE_LAYER_TEST_RESULTS;
  const activeLayerIsRx = activeTillageLayerId === TILLAGE_LAYER_RX;

  useSetActiveLayerSourceData(
    mapRef,
    mapHasLoaded,
    activeTillageLayerId,
    field,
    testResultsGeojsonUri,
    rxGeojsonUri,
  );

  useToggleActivePolyLayerVisibility(mapRef, mapHasLoaded, activeTillageLayerId);

  if (availableLayersQuery.isPending) {
    return (
      <Group gap="lg">
        <Skeleton w={100} />
        <Skeleton w={100} />
        <Skeleton w={100} />
      </Group>
    );
  }

  if (availableLayersQuery.isError) {
    return (
      <Alert
        variant="light"
        color="red.8"
        maw={575}
        mx="auto"
        title={availableLayersQuery.error?.message}
      />
    );
  }

  if (!availableLayersQuery.data.layers.length) {
    return <p>{getString('noData', language)}</p>;
  }

  const ActiveLayerControl = (
    <SegmentedControl
      value={activeTillageLayerId}
      data={[
        { label: getString('testResults', language), value: tillagePolygonLayerIds.testResults },
        { label: getString('rx', language), value: tillagePolygonLayerIds.rx },
      ]}
      onChange={(value) => {
        const activeLayerId = value as TillageLayerId;

        onActiveLayerChange(activeLayerId);

        if (!mapRef.current || !mapHasLoaded) {
          return;
        }

        if (activeLayerId === 'testResults' && dcpIsChecked) {
          toggleLayerVisibility(mapRef.current, DCP_POINTS_LAYER_ID, true);
        } else if (activeLayerId === 'rx') {
          toggleLayerVisibility(mapRef.current, DCP_POINTS_LAYER_ID, false);
        }
      }}
    />
  );

  return (
    <Flex gap="lg" mb="xs" direction="column" {...rootFlexProps}>
      <Group gap="lg">
        {ActiveLayerControl}
        {activeLayerIsRx && rxGeojsonUri && (
          <DownloadScriptBtn url={rxGeojsonUri} fieldName={field.properties.name} />
        )}
        {mapDisplayTypeToggle}
        {activeLayerIsTestResults && (
          <DcpPointsToggle
            isChecked={dcpIsChecked}
            onChange={(checked) => {
              setDcpIsChecked(checked);

              if (mapRef.current && mapHasLoaded) {
                toggleLayerVisibility(mapRef.current, DCP_POINTS_LAYER_ID, checked);
              }
            }}
          />
        )}
      </Group>
      {activeLayerIsTestResults && (
        <CompactionTestResultsSlider
          availableDcpLayers={availableDcpLayers || []}
          setGeojsonUri={(uri) => setTestResultsGeojsonUri(uri)}
          availableLayers={availableLayersQuery.data.layers}
          setInferenceLayer={setInferenceLayer}
          onChange={onSliderChange}
          setDcpUri={(uri) => {
            if (mapHasLoaded && mapRef.current) {
              setSourceData(mapRef.current, DCP_POINTS_LAYER_ID, uri);
            }
          }}
        />
      )}
      {activeLayerIsRx && (
        <RxResultsSlider
          setGeojsonUri={setRxGeojsonUri}
          availableDcpLayers={availableDcpLayers || []}
          availableLayers={availableLayersQuery.data.layers}
          inline={inlineRxResultsSlider}
          setInferenceLayer={setInferenceLayer}
          onChange={onSliderChange}
          setDcpUri={(uri) => {
            if (mapHasLoaded && mapRef.current) {
              setSourceData(mapRef.current, DCP_POINTS_LAYER_ID, uri);
            }
          }}
        />
      )}
    </Flex>
  );
};
