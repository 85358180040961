import { applyMiddleware, compose, createStore, Action } from 'redux';
import thunk, { ThunkAction } from 'redux-thunk';

import rootReducer from '../reducers/rootReducer';
import { UserStateType } from './user/types';
import { AttributesStateType } from './attributes/types';
import { FeatureFlagsState } from './featureFlags/types';
import { FieldEventsStateType } from './fieldEvents/reducers';
import { JobsStateType } from './jobs/reducers';
import { OperationStateType } from './operation/reducers';
import { FieldStateType } from './fields/reducers';
import { StateStateType } from './states/reducers';
import { ChemistryResultsStateType } from './chemistryResults/types';
import { SampleState } from './samples/types';
import { LabSampleStateType } from './labSamples/types';
import { SamplePlanStateType } from './samplePlans/reducer';
import { RoleStateType } from './roles/types';
import { PaymentsStateType } from './payments/reducers';
import { AnalyticsState } from './analytics/types';
import { RecommendationsStateType } from './recommendations/types';
import { RecommendationSetStateType } from './recommendationSets/reducer';
import { AgencyStateType } from './agencies/types';
import { TimingStateType } from './samplingTimings/reducer';
import { InputsState } from './inputs/types';
import { PrescriptionZoneState } from './prescriptions/types';
import { ShippingStateType } from './shipping/types';
import { SamplePricesStateType } from './samplePrices/types';
import { PlatesStateType } from './plates/types';
import { BatchStateType } from './batches/types';
import { ControlsStateType } from './controls/types';
import { IndexSetsStateType } from './indexSets/types';
import { DNAConcentrationsStateType } from './dnaConcentration/types';
import { ZoneAnalysisStateType as ZoneAnalysisStateTypeV2 } from './zoneAnalysisV2/reducer';
import { SamplingHistoryStateType } from './samplingHistory/types';
import { TerritoryStateType } from './commercialTerritories/types';
import { SalesFlowStateType } from './salesFlow/types';
import { LabShipmentStateType } from './labShipments/types';
import { NaptControlsStateType } from './naptControls/types';
import { HeatMapStateType } from './heatMap/types';
import { OrderHistoryStateType } from './orderHistory/types';
import { CropPlanningStateType } from './cropPlans/types';
import { CatalogStateType } from './catalogs/reducer';

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = () => createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

export default configureStore;

export interface RootState {
  analytics: AnalyticsState;
  catalogs: CatalogStateType;
  chemistryResults: ChemistryResultsStateType;
  controls: ControlsStateType;
  cropPlanning: CropPlanningStateType;
  naptControls: NaptControlsStateType;
  fieldEvents: FieldEventsStateType;
  fieldGeometry: FieldStateType;
  stateGeometry: StateStateType;
  heatMap: HeatMapStateType;
  indexSets: IndexSetsStateType;
  inputs: InputsState;
  jobs: JobsStateType;
  DNAConcentrations: DNAConcentrationsStateType;
  operations: OperationStateType;
  orderHistory: OrderHistoryStateType;
  attributes: AttributesStateType;
  featureFlags: FeatureFlagsState;
  roles: RoleStateType;
  batches: BatchStateType;
  labSamples: LabSampleStateType;
  labShipments: LabShipmentStateType;
  plates: PlatesStateType;
  samples: SampleState;
  samplePlans: SamplePlanStateType;
  samplingHistory: SamplingHistoryStateType;
  user: UserStateType;
  recommendations: RecommendationsStateType;
  recommendationSets: RecommendationSetStateType;
  prescriptionZones: PrescriptionZoneState;
  timings: TimingStateType;
  payments: PaymentsStateType;
  agencies: AgencyStateType;
  shipping: ShippingStateType;
  samplePrices: SamplePricesStateType;
  zoneAnalysisV2: ZoneAnalysisStateTypeV2;
  commercialTerritories: TerritoryStateType;
  salesFlow: SalesFlowStateType;
}

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, null, Action<string>>;
