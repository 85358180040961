import { queryOptions } from '@tanstack/react-query';
import { getGroupedCollections } from 'store/eoCollections/requests';
import { dataReviewQueryKeys, DEFAULT_COLLECTION_GROUP_QUERY_OPTIONS } from './queryKeys';

/** **Data Review** queries */
export const collectionGroupQueryOptions = (filters = DEFAULT_COLLECTION_GROUP_QUERY_OPTIONS) => {
  return queryOptions({
    queryKey: dataReviewQueryKeys.filteredGroupedCollections(filters),
    queryFn: () => getGroupedCollections(filters),
  });
};
