import React from 'react';
import { Checkbox, Flex, Select, Space, Switch, Text } from '@mantine/core';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';

import AnalysisProductPrice from './AnalysisProductPrice';
import AnalysisProductSelect from './AnalysisProductSelect';

const getTranslations = (language: string) => ({
  analysis: getString('analysis', language),
  product: getString('product', language),
});

const AnalysisSamplingSection = () => {
  const language = useBroswerLanguage();
  const translations = getTranslations(language);

  return (
    <div>
      <Text fz="h4" fw="bold" component="h2" mb="sm">
        {translations.analysis}
      </Text>
      <Flex justify="space-between" align="flex-start" maw={800} gap="xl">
        <AnalysisProductSelect label={translations.product} onChange={() => {}} />
        <Text size="sm" fs="italic" flex={1}>
          Includes TruNutrient, TruBio, and TruTill, giving you the most complete analysis of the
          soil environment to plan fertility, crop protection, biofertility, and tillage activities
          for next season!
        </Text>
        <AnalysisProductPrice language={language} price={21.24} />
      </Flex>
      <Space h="md" />
      <Flex
        display="grid"
        style={{ gridTemplateColumns: 'auto auto auto auto' }}
        maw={600}
        columnGap="xl"
        rowGap="lg"
        align="center"
      >
        <Switch size="md" label="Nutrient" />
        <Checkbox size="sm" label="TruNutrient" />
        <Select
          aria-label="lab tests"
          value={'STANDARD_NUTRIENT'}
          data={[{ label: 'Standard Nutrient (no nitrate)', value: 'STANDARD_NUTRIENT' }]}
        />
        <span>+</span>
        <Switch size="md" label="TruBio" />
        <div />
        <div />
        <div />
        <Switch size="md" label="TruTill" />
        <div />
        <div />
        <div />
      </Flex>
    </div>
  );
};

export default AnalysisSamplingSection;
