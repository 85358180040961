import { SegmentedControl } from '@mantine/core';
import React, { useState } from 'react';
import { DataReviewStatus } from 'store/eoCollections/types';
import { getDataReviewStatusOptions } from 'util/dataReview';

type Props = {
  language: string;
  onChange: (status: DataReviewStatus) => void;
};

const DataReviewStatusSwitcher = ({ onChange, language }: Props) => {
  const options = getDataReviewStatusOptions(language);
  const [value, setValue] = useState<DataReviewStatus>('pending');

  return (
    <SegmentedControl
      data={options}
      value={value}
      onChange={(val) => {
        const status = val as DataReviewStatus;

        setValue(status);
        onChange(status);
      }}
    />
  );
};

export default DataReviewStatusSwitcher;
