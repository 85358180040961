import React, { useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { Button, Group, Stack, Title } from '@mantine/core';
import { TabbedController } from 'common';
import { getString } from 'strings/translation';
import createEmptyWell, { ENTRY_TYPES } from 'constants/emptyWell';
import { PlateType, WellType } from 'store/plates/types';
import {
  generateWellsIndex,
  getMaxWellsByPlateType,
  getNextEmptyWell,
  getPlateDisplayName,
} from 'util/plates';
import { addWellToPlate } from 'store/plates/thunks';

import styles from './Container.module.css';
import { ExistingSampleForm } from './ExistingSampleForm';
import { ControlSampleForm } from './ControlSampleForm';

type CreateWellContainerPropsType = {
  startingWell: WellType;
  plate: PlateType;
  onClose: () => void;
};

export const CreateWellContainer = ({
  startingWell,
  plate,
  onClose,
}: CreateWellContainerPropsType) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const barcodeInput = useRef<HTMLInputElement>(null);
  const [isFormValid, setIsFormValid] = useState(true);
  const [entryTypeIndex, setEntryTypeIndex] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [well, setWell] = useState<WellType>(startingWell);

  useEffect(() => {
    if (barcodeInput && barcodeInput.current) {
      barcodeInput.current.focus();
    }
  }, [entryTypeIndex]);

  const { maxRows, maxCols } = getMaxWellsByPlateType(plate.plate_type);

  const wellLookupMap = generateWellsIndex(plate.wells, startingWell.plate_quadrant);

  const resetForNextWell = () => {
    const { row, column } =
      getNextEmptyWell(wellLookupMap, well.row, well.column, maxRows, maxCols) || {};
    if (row && column) {
      setErrorMessage('');
      setWell(createEmptyWell(row, column, plate.barcode, startingWell.plate_quadrant));
    } else {
      onClose();
    }
  };

  const submitWell = async () => {
    try {
      await dispatch(addWellToPlate(plate.barcode, well));
      resetForNextWell();
      if (barcodeInput && barcodeInput.current) {
        barcodeInput.current.focus();
      }
    } catch (e) {
      setErrorMessage(e.message);
    }
  };

  const handleOnValueChange = (
    attributeName: string,
    newValue: string | string[] | { [key: string]: any } | number | boolean | null,
  ) => {
    setWell(
      (wellForm) =>
        ({
          ...wellForm,
          [attributeName]: newValue,
        }) as WellType,
    );
  };

  const handleFormChange = (idx: number) => {
    setEntryTypeIndex(idx);
    // Only keep attributes which persist between forms
    setWell((wellForm) =>
      createEmptyWell(wellForm.row, wellForm.column, plate.barcode, wellForm.plate_quadrant),
    );
    setIsFormValid(true);
    setErrorMessage('');
  };

  const entryTypeSelectorOptions = [
    {
      displayName: getString(ENTRY_TYPES.BARCODE, language),
      id: 0,
    },
    {
      displayName: getString(ENTRY_TYPES.CONTROL, language),
      id: 1,
    },
  ];

  return (
    <Stack align="center" gap="0.25rem" w="25rem" mih="30rem" pb="xl">
      <Title order={3}>
        {`${getString('awaitingWellInpuMsg', language)}: ${well.row.toUpperCase()}${well.column}`}
      </Title>
      <Title order={4} fw="bold">
        {`${getPlateDisplayName(plate.plate_type, language)}: ${plate.barcode} (${
          plate.homogenization_plate_id
        })`}
      </Title>
      <Stack gap="sm" w="100%">
        <Group justify="center">
          <TabbedController
            activeIndex={entryTypeIndex}
            className={styles.FormTypeSelector}
            onChange={handleFormChange}
            options={entryTypeSelectorOptions}
          />
        </Group>
        <Group gap="0.25rem">
          <Button
            disabled={!well.sample_barcode?.trim() || !isFormValid}
            onClick={submitWell}
            variant="filled"
          >
            {getString('save', language)}
          </Button>
          <Button onClick={onClose} variant="outline">
            {getString('cancel', language)}
          </Button>
        </Group>
        {entryTypeIndex === 0 && (
          <ExistingSampleForm
            plate={plate}
            well={well}
            onValueChange={handleOnValueChange}
            setIsFormValid={setIsFormValid}
            setErrorMessage={setErrorMessage}
            errorMessage={errorMessage}
            barcodeRef={barcodeInput}
            submitForm={submitWell}
          />
        )}
        {entryTypeIndex === 1 && (
          <ControlSampleForm plate={plate} well={well} onValueChange={handleOnValueChange} />
        )}
      </Stack>
    </Stack>
  );
};
