import { GroupedCollectionsListPayload } from 'store/eoCollections/types';

export const DEFAULT_COLLECTION_GROUP_QUERY_OPTIONS: GroupedCollectionsListPayload = {
  groupBy: 'operation',
  sortBy: 'name',
  page: 1,
  status: 'pending',
};

export const samplingPlansQueryKeys = {
  externalLab: (samplingPlanId: number) => ['externalLab', samplingPlanId],
};

export const skusQueryKeys = {
  allSkus: ['allSkus'],
};

export const dataReviewQueryKeys = {
  groupedCollections: () => ['groupedCollections'],
  filteredGroupedCollections: (params = DEFAULT_COLLECTION_GROUP_QUERY_OPTIONS) => [
    ...dataReviewQueryKeys.groupedCollections(),
    { ...params },
  ],
  collectionsByField: (fieldId: number, status: GroupedCollectionsListPayload['status']) => [
    ...dataReviewQueryKeys.groupedCollections(),
    fieldId,
    status,
  ],
};

export const pricingQueryKeys = {
  defaultContract: ['defaultContract'],
};

export const resultsQueryKeys = {
  /** List of available layers */
  samplePlanTillageLayers: (samplingPlanId?: number) => ['tillageLayers', samplingPlanId],
};
