import { handleJsonResponse, requestGet, requestPost, SERVICE_URL } from 'util/request';
import { SkuType, ContractV2Type, ContractSkuType } from './types';

export const getAllSkus = async () => handleJsonResponse(await requestGet(`${SERVICE_URL}/skus/`));

export const createSku = async (sku: Partial<SkuType>) =>
  handleJsonResponse(await requestPost(`${SERVICE_URL}/skus/`, { body: sku }));

export const getDefaultContracts = async () =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/default_contracts/`));

export const createDefaultContract = async (contract: Partial<ContractV2Type>) =>
  handleJsonResponse(await requestPost(`${SERVICE_URL}/default_contracts/`, { body: contract }));

export const addSkuToContract = async (contractId: number, skuContract: Partial<ContractSkuType>) =>
  handleJsonResponse(
    await requestPost(await `${SERVICE_URL}/contract_skus/${contractId}`, {
      body: skuContract,
    }),
  );
