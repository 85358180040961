import React, { useState } from 'react';
import { CloseButton, Loader, TextInput } from '@mantine/core';
import { useDebouncedCallback } from '@mantine/hooks';
import { getString } from 'strings/translation';
import { FaSearch } from 'react-icons/fa';
import { DataReviewGroupViewType } from 'store/eoCollections/types';

type Props = {
  language: string;
  groupViewBy: DataReviewGroupViewType;
  onChange: (value: string) => void;
};

const DataReviewSearch = ({ onChange, language, groupViewBy }: Props) => {
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);

  const text = getString(
    groupViewBy === 'operation' ? 'searchForGrower' : 'searchForDealer',
    language,
  );

  const handleSearch = useDebouncedCallback(async (query: string) => {
    setLoading(true);
    onChange(query);
    setLoading(false);
  }, 300);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSearch(value);
    handleSearch(value);
  };

  const handleClear = () => {
    setSearch('');
    onChange('');
  };

  const getRightSection = () => {
    if (loading) {
      return <Loader size={20} />;
    }

    if (search) {
      return <CloseButton onClick={handleClear} />;
    }

    return null;
  };

  return (
    <TextInput
      aria-label={text}
      flex={1}
      inputMode="text"
      leftSection={<FaSearch />}
      maw={400}
      ml="auto"
      onChange={handleChange}
      placeholder={text}
      rightSection={getRightSection()}
      size="md"
      value={search}
    />
  );
};

export default DataReviewSearch;
