import React, { useState } from 'react';
import { Accordion, Badge, Checkbox, Divider, Group, Stack, Text } from '@mantine/core';

import { FONT_WEIGHT_BOLD } from 'constants/mantine';
import {
  EOCollectionFieldFeatCollection,
  EOCollectionFieldType,
  GroupedCollectionsListPayload,
} from 'store/eoCollections/types';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { isPlanCancelled, sortPlansByDate } from 'util/samplePlan';

import DataReviewItemContent from './DataReviewItemContent';

interface FieldCollectionsProps {
  fieldFeatureCollection: EOCollectionFieldFeatCollection;
  selectedFields: EOCollectionFieldType[];
  toggleField: (fieldProperties: EOCollectionFieldType) => void;
  currentGetPayload: GroupedCollectionsListPayload;
  accordionPanelIsOpen: boolean;
}

const FieldCollections = ({
  fieldFeatureCollection,
  selectedFields,
  toggleField,
  currentGetPayload,
  accordionPanelIsOpen,
}: FieldCollectionsProps) => {
  const language = useBroswerLanguage();
  const firstField = fieldFeatureCollection.features[0];

  const {
    acreage,
    acreage_unit,
    farm_name,
    id: fieldId,
    name,
    pending_eo_collections,
    sampling_plans,
  } = firstField.properties;

  const [selectedCollectionId, setSelectedCollectionId] = useState<number | null>(
    pending_eo_collections[0]?.id || null,
  );

  const lastPlan = sortPlansByDate(sampling_plans).filter((p) => !isPlanCancelled(p))[0];

  if (!(lastPlan && pending_eo_collections.length)) {
    return null;
  }

  const totalOverlap = pending_eo_collections.reduce(
    (total, collection) => total + Math.round(collection.overlap_percentage),
    0,
  );

  return (
    <Accordion.Item value={fieldId.toString()}>
      <Accordion.Control>
        <Group>
          {currentGetPayload.status === 'pending' && (
            <Checkbox
              aria-label={getString('selectField', language)}
              checked={selectedFields.map((f) => f.id).includes(fieldId)}
              color="blue"
              disabled={!accordionPanelIsOpen}
              onChange={() => toggleField(firstField.properties)}
              onClick={(e) => e.stopPropagation()}
              styles={{
                input: { cursor: 'pointer' },
                label: { cursor: 'pointer' },
              }}
            />
          )}
          <Stack gap={4}>
            <Group gap="xs">
              <div>
                <Text fw={FONT_WEIGHT_BOLD} size="sm">
                  {`${farm_name ? `${farm_name}:` : ''} ${name}`}
                </Text>
                {currentGetPayload.groupBy === 'agency' && (
                  <Text c="dimmed" size="xs">
                    {firstField.properties.operation.name}
                  </Text>
                )}
              </div>
              <Badge color="blue" variant="light">
                {pending_eo_collections.length}{' '}
                {getString(
                  pending_eo_collections.length === 1 ? 'collection' : 'collections',
                  language,
                )}{' '}
                ({`${Math.round(acreage)} ${acreage_unit}`})
              </Badge>
            </Group>
            <Group c="dimmed" fz="sm" gap="xs">
              {lastPlan.name}
              <Divider orientation="vertical" h="md" style={{ alignSelf: 'center' }} />
              {`${getString('totalScanCoverage', language)}: ${totalOverlap}%`}
            </Group>
          </Stack>
        </Group>
      </Accordion.Control>
      <Accordion.Panel
        styles={{
          content: {
            display: 'flex',
            justifyContent: 'space-between',
            gap: 'var(--mantine-spacing-sm)',
          },
        }}
      >
        {accordionPanelIsOpen && (
          <DataReviewItemContent
            fieldFeatureCollection={fieldFeatureCollection}
            fieldId={fieldId}
            language={language}
            onRowClick={(id) => setSelectedCollectionId(id)}
            selectedCollectionId={selectedCollectionId}
            currentGetPayload={currentGetPayload}
          />
        )}
      </Accordion.Panel>
    </Accordion.Item>
  );
};

export default FieldCollections;
