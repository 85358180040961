import React from 'react';
import { Center, Loader, Stack, Table } from '@mantine/core';

import {
  EOCollectionFieldFeatCollection,
  GroupedCollectionsListPayload,
} from 'store/eoCollections/types';
import { getString } from 'strings/translation';

import CollectionMap from './CollectionMap';
import { useQuery } from '@tanstack/react-query';
import { getGroupedCollectionsForOneField } from 'store/eoCollections/requests';
import { dataReviewQueryKeys } from 'util/queryKeys';
import DataReviewCollectionRow from './DataReviewCollectionRow';
import CenteredErrorMessage from 'common/Components/CenteredErrorMessage';

type Props = {
  fieldFeatureCollection: EOCollectionFieldFeatCollection;
  fieldId: number;
  language: string;
  selectedCollectionId: number | null;
  currentGetPayload: GroupedCollectionsListPayload;
  onRowClick: (id: number | null) => void;
};

const DataReviewItemContent = ({
  fieldFeatureCollection,
  fieldId,
  language,
  onRowClick,
  selectedCollectionId,
  currentGetPayload,
}: Props) => {
  const query = useQuery({
    queryKey: dataReviewQueryKeys.collectionsByField(fieldId, currentGetPayload.status),
    queryFn: () => getGroupedCollectionsForOneField(currentGetPayload, fieldId),
  });

  if (query.isPending) {
    return (
      <Center h="40vh">
        <Loader />
      </Center>
    );
  }

  if (query.isError) {
    return <CenteredErrorMessage title={query.error.message} centerProps={{ h: '6rem' }} />;
  }

  if (query.data) {
    return (
      <>
        <Stack flex={1}>
          <Table>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>{getString('collectionName', language)}</Table.Th>
                <Table.Th>{getString('collectionDate', language)}</Table.Th>
                <Table.Th>{getString('scannedBy', language)}</Table.Th>
                <Table.Th>{getString('scanningTime', language)}</Table.Th>
                <Table.Th>{getString('fieldCoverage', language)}</Table.Th>
                <Table.Th>ADCP</Table.Th>
                <Table.Th>EMI</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {query.data.eo_collections.map((collection) => (
                <DataReviewCollectionRow
                  key={collection.id}
                  isDeletable={currentGetPayload.status === 'pending'}
                  collection={collection}
                  language={language}
                  onRowClick={onRowClick}
                  selectedCollectionId={selectedCollectionId}
                />
              ))}
            </Table.Tbody>
          </Table>
        </Stack>
        <CollectionMap
          field={fieldFeatureCollection}
          selectedCollectionId={selectedCollectionId}
          collections={query.data.eo_collections}
        />
      </>
    );
  }

  return null;
};

export default DataReviewItemContent;
