import React, { useEffect, useState } from 'react';
import { TextInput, Modal, Grid, Select, Group, Button, Space } from '@mantine/core';
import { ContractSkuType, SkuType } from 'store/pricing/types';
import { PRICE_TYPES } from 'constants/pricing';
import showToast from 'actions/toastActions';

interface Props {
  opened: boolean;
  onClose: () => void;
  skuOptions: SkuType[];
  modalDefaults: { skuId: number; priceType: string } | null;
  onSubmit: (sku: Partial<ContractSkuType>) => void;
}

const AddSkuModal = ({ opened, onClose, skuOptions, modalDefaults, onSubmit }: Props) => {
  const [contractSku, setContractSku] = useState<Partial<ContractSkuType>>({});

  useEffect(() => {
    if (modalDefaults) {
      setContractSku({
        ...contractSku,
        sku_id: modalDefaults.skuId,
        price_type: modalDefaults.priceType,
      });
    }
  }, [modalDefaults]);

  const setContractSkuAttribute = (
    attributeKey: keyof ContractSkuType,
    newValue: string | number,
  ) => {
    setContractSku({ ...contractSku, [attributeKey]: newValue });
  };

  const closeSkuModal = () => {
    setContractSku({});
    onClose();
  };

  const submitForm = async () => {
    if (!contractSku.sku_id || !contractSku.price_type) {
      return showToast('Please fill all required fields', 'error');
    }
    try {
      await onSubmit(contractSku);
      setContractSku({});
      closeSkuModal();
      showToast('SKU added successfully');
    } catch (error) {
      showToast(error.message, 'error');
    }
  };

  return (
    <Modal title="Add SKU to Contract" centered opened={opened} onClose={onClose} size="lg">
      <Grid grow>
        <Grid.Col span={6}>
          <Select
            data={skuOptions.map((sku) => ({ value: String(sku.id), label: sku.name, ...sku }))}
            label="SKU"
            placeholder="Select SKU"
            required
            value={String(contractSku?.sku_id) || null}
            onChange={(value) => value && setContractSkuAttribute('sku_id', Number(value))}
            disabled={Boolean(modalDefaults)}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <Select
            data={PRICE_TYPES}
            label="Price Type"
            placeholder="Per Acre / Sample"
            required
            value={contractSku?.price_type || null}
            onChange={(value) => value && setContractSkuAttribute('price_type', value)}
            disabled={Boolean(modalDefaults)}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextInput
            label="Min Density"
            type="number"
            onChange={(val) =>
              setContractSkuAttribute('min_density', Number(val.currentTarget.value))
            }
            value={String(contractSku?.min_density) || ''}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextInput
            label="Max Density"
            type="number"
            onChange={(val) =>
              setContractSkuAttribute('max_density', Number(val.currentTarget.value))
            }
            value={String(contractSku?.max_density) || ''}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextInput
            label="Agency Price"
            type="number"
            onChange={(val) =>
              setContractSkuAttribute('agency_price', Number(val.currentTarget.value))
            }
            value={String(contractSku?.agency_price)}
            rightSection="$"
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextInput
            label="List Price"
            type="number"
            onChange={(val) =>
              setContractSkuAttribute('list_price', Number(val.currentTarget.value))
            }
            value={String(contractSku?.list_price)}
            rightSection="$"
          />
        </Grid.Col>
      </Grid>
      <Space h="xl" />
      <Group justify="flex-end">
        <Button variant="outline" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={submitForm}>Save</Button>
      </Group>
    </Modal>
  );
};

export default AddSkuModal;
