import { SegmentedControl } from '@mantine/core';
import React from 'react';
import { OrderHistoryStatusFilter } from 'store/orderHistory/types';
import { getString } from 'strings/translation';

type Props = {
  currentValue: OrderHistoryStatusFilter;
  language: string;
  onChange: (value: OrderHistoryStatusFilter) => void;
};

const getOptions = (
  language: string,
): Array<{ value: OrderHistoryStatusFilter; label: string }> => [
  {
    value: 'active-and-completed',
    label: getString('activeAndCompletedPlans', language),
  },
  {
    value: 'completed',
    label: getString('completed', language),
  },
];

const OrderHistoryStatusControl = ({ currentValue, onChange, language }: Props) => {
  return (
    <SegmentedControl
      value={currentValue}
      onChange={(val) => onChange(val as OrderHistoryStatusFilter)}
      data={getOptions(language)}
    />
  );
};

export default OrderHistoryStatusControl;
