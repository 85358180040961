import React from 'react';
import { Flex } from '@mantine/core';
import { ND, NOT_DETECTED } from 'constants/fieldRisks';

import styles from './RiskLegendV2.module.css';

interface PropTypes {
  risk: string;
  content?: React.ReactNode;
}

const RiskLegendSymbol = ({ risk, content }: PropTypes) => {
  return (
    <Flex
      className={styles[risk] || ''}
      align="center"
      justify="center"
      h={13}
      w={13}
      style={{
        borderRadius: 'var(--mantine-radius-sm)',
      }}
    >
      {content || (risk === NOT_DETECTED ? ND : '')}
    </Flex>
  );
};

export default RiskLegendSymbol;
