import { FieldType } from 'store/fields/types';
import { InputType } from 'store/inputs/types';
import { PrescriptionType, PrescriptionZoneType } from 'store/prescriptions/types';
import {
  PHOSPHORUS_ID,
  POTASSIUM_ID,
  PH_ID,
  SHAPEFILE_FORMAT,
  P_BRAY_ID,
  P_OLSEN_ID,
  K_AMMONIUM_ACETATE_ID,
  BASE_SATURATION,
  BCSR,
  SOIL_ATTRIBUTES,
  PRO_PRESCRIPTION_SYMBOL_BLACKLIST,
} from 'constants/results';
import { NOT_APPLICABLE, NO_DATA } from 'constants/defaultValues';

import {
  CUSTOM_CCE_ISU,
  CUSTOM_CCE_SDSU,
  CUSTOM_ENP_OHIO,
  CUSTOM_NV_MICHIGAN,
  CUSTOM_RNV_ENM_ISU,
  CUSTOM_RNV_INDIANA,
  CUSTOM_RNV_SDSU,
  AG_LEADER_RX,
  CNH_RX,
  INPUT_10_34_0_20_PERCENT_ID,
  JOHN_DEERE_RX,
  LIME_ISU_60,
  LIME_ISU_65,
  LIME_ISU_69,
  LIME_SDSU,
  LIME_TRI_STATE_60,
  LIME_TRI_STATE_65,
  LIME_TRI_STATE_68,
  LIME_TRI_STATE_OH_60,
  LIME_TRI_STATE_OH_65,
  LIME_TRI_STATE_OH_68,
  PERCENT,
  PHOSPHORUS_ISU,
  PHOSPHORUS_SDSU_MN_BRAY,
  PHOSPHORUS_SDSU_MN_OLSEN,
  PHOSPHORUS_TRI_STATE,
  POTASSIUM_ISU,
  POTASSIUM_SDSU_MN,
  POTASSIUM_TRI_STATE,
  RAVEN_ENVIZIOPRO_RX,
  RAVEN_VIPER4_RX,
  RAVEN_VIPERPPRO_RX,
  formulaDisplayNames,
  SATURATION_BRAZIL,
  CUSTOM_RNP_BRAZIL,
  PHOSPHORUS_BRAZIL,
  POTASSIUM_BRAZIL,
  AMOUNT_KEY,
  PRO_PRESCRIPTIONS_NO_OUTLINE_DENSITIES,
} from 'constants/prescription';
import { K, N, P, PH } from 'constants/chemistry';
import { capitalizeEveryWord } from './stringUtils';
import { getMonthString } from './samplePlan';
import { getTonSize, getUnitBuAc, getUnitLbs, getUnitLbsAc } from './units';
import { convertDecimalToPercent, isNumber, roundTwoDecimal } from './numUtils';
import { getString } from '../strings/translation';
import { BR, US } from 'constants/countries';
import { SampleFeatureType } from 'store/samples/types';
import { OperationType } from 'store/operation/types';
import { FillPaint, GeoJSONSource, Map } from 'mapbox-gl';
import { ReactNode } from 'react';
import { PRESCRIPTION_COLORS, WHITE } from './mapImageryColors';

export const getExportTypes = (
  language: string,
  external_connected_accounts?: OperationType['external_connected_accounts'],
) => [
  {
    id: 0,
    label: getString('downloadShpfile', language),
    displayName: getString('downloadShpfile', language),
    value: SHAPEFILE_FORMAT,
    leaf_user_uuid: null,
  },
  {
    id: 1,
    label: 'John Deere - GS 2, GS 3, Gen 4',
    displayName: 'John Deere - GS 2, GS 3, Gen 4',
    value: JOHN_DEERE_RX,
    leaf_user_uuid: null,
  },
  {
    id: 2,
    label: 'CNH Pro 700 / 1200 & Intelliview IV',
    displayName: 'CNH Pro 700 / 1200 & Intelliview IV',
    value: CNH_RX,
    leaf_user_uuid: null,
  },
  {
    id: 3,
    label: 'Ag Leader - Versa / InCommand',
    displayName: 'Ag Leader - Versa / InCommand',
    value: AG_LEADER_RX,
    leaf_user_uuid: null,
  },
  {
    id: 4,
    label: 'Raven - Viper Pro',
    displayName: 'Raven - Viper Pro',
    value: RAVEN_VIPERPPRO_RX,
    leaf_user_uuid: null,
  },
  {
    id: 5,
    label: 'Raven - Viper 4',
    displayName: 'Raven - Viper 4',
    value: RAVEN_VIPER4_RX,
    leaf_user_uuid: null,
  },
  {
    id: 6,
    label: 'Raven - Envizio Pro',
    displayName: 'Raven - Envizio Pro',
    value: RAVEN_ENVIZIOPRO_RX,
    leaf_user_uuid: null,
  },
  ...(external_connected_accounts?.accounts.map((accountName, idx) => ({
    id: idx + 7,
    label: `${getString('sendTo', language)} ${getString(accountName, language)}`,
    displayName: `${getString('sendTo', language)} ${getString(accountName, language)}`,
    value: accountName,
    leaf_user_uuid: external_connected_accounts.leaf_user_uuid,
  })) || []),
];

export const getFormulaDisplayName = (formula: string, language: string) =>
  getString(formulaDisplayNames[formula], language);

export const getTimingOptions = (language: string) => [
  {
    id: 1,
    displayName: getString('preplant', language),
    value: 'preplant',
  },
  {
    id: 2,
    displayName: getString('starter', language),
    value: 'starter',
  },
  {
    id: 3,
    displayName: getString('sidedress', language),
    value: 'sidedress',
  },
  {
    id: 4,
    displayName: getString('foliar', language),
    value: 'foliar',
  },
];

export const getCropOptions = (language: string) => [
  {
    id: 1,
    displayName: getString('corn', language),
    value: 'corn',
  },
  {
    id: 2,
    displayName: getString('soybeans', language),
    value: 'soybeans',
  },
];

export const getTillageOptions = (language: string) => [
  {
    id: 1,
    displayName: getString('conventionalTillage', language),
    value: 'conventionalTillage',
  },
  {
    id: 2,
    displayName: getString('reducedNoTill', language),
    value: 'reducedNoTill',
  },
];

export const getNutrientSelectorOptions = (language: string) => [
  {
    id: PHOSPHORUS_ID,
    value: P,
    displayName: getString('phosphorus', language),
  },
  {
    id: POTASSIUM_ID,
    value: K,
    displayName: getString('potassium', language),
  },
  {
    id: PH_ID,
    value: PH,
    displayName: getString('lime', language),
  },
];

export const getFormulaOptions = (language: string, userId?: number) => {
  const baseList = [
    {
      id: 1,
      value: PHOSPHORUS_ISU,
      displayName: getFormulaDisplayName(PHOSPHORUS_ISU, language),
      nutrient: P,
      countryCodes: [US],
    },
    {
      id: 2,
      value: LIME_ISU_60,
      displayName: getFormulaDisplayName(LIME_ISU_60, language),
      nutrient: PH,
      countryCodes: [US],
    },
    {
      id: 3,
      value: LIME_ISU_65,
      displayName: getFormulaDisplayName(LIME_ISU_65, language),
      nutrient: PH,
      countryCodes: [US],
    },
    {
      id: 4,
      value: LIME_ISU_69,
      displayName: getFormulaDisplayName(LIME_ISU_69, language),
      nutrient: PH,
      countryCodes: [US],
    },
    {
      id: 5,
      value: POTASSIUM_ISU,
      displayName: getFormulaDisplayName(POTASSIUM_ISU, language),
      nutrient: K,
      countryCodes: [US],
    },
    {
      id: 6,
      value: PHOSPHORUS_TRI_STATE,
      displayName: getFormulaDisplayName(PHOSPHORUS_TRI_STATE, language),
      nutrient: P,
      countryCodes: [US],
    },
    {
      id: 7,
      value: POTASSIUM_TRI_STATE,
      displayName: getFormulaDisplayName(POTASSIUM_TRI_STATE, language),
      nutrient: K,
      countryCodes: [US],
    },
    {
      id: 8,
      value: LIME_TRI_STATE_60,
      displayName: getFormulaDisplayName(LIME_TRI_STATE_60, language),
      nutrient: PH,
      countryCodes: [US],
    },
    {
      id: 9,
      value: LIME_TRI_STATE_65,
      displayName: getFormulaDisplayName(LIME_TRI_STATE_65, language),
      nutrient: PH,
      countryCodes: [US],
    },
    {
      id: 10,
      value: LIME_TRI_STATE_68,
      displayName: getFormulaDisplayName(LIME_TRI_STATE_68, language),
      nutrient: PH,
      countryCodes: [US],
    },
    {
      id: 14,
      value: PHOSPHORUS_SDSU_MN_OLSEN,
      displayName: getFormulaDisplayName(PHOSPHORUS_SDSU_MN_OLSEN, language),
      nutrient: P,
      countryCodes: [US],
    },
    {
      id: 15,
      value: PHOSPHORUS_SDSU_MN_BRAY,
      displayName: getFormulaDisplayName(PHOSPHORUS_SDSU_MN_BRAY, language),
      nutrient: P,
      countryCodes: [US],
    },
    {
      id: 16,
      value: POTASSIUM_SDSU_MN,
      displayName: getFormulaDisplayName(POTASSIUM_SDSU_MN, language),
      nutrient: K,
      countryCodes: [US],
    },
    {
      id: 17,
      value: LIME_SDSU,
      displayName: getFormulaDisplayName(LIME_SDSU, language),
      nutrient: PH,
      countryCodes: [US],
    },
    {
      id: 18,
      value: SATURATION_BRAZIL,
      displayName: getFormulaDisplayName(SATURATION_BRAZIL, language),
      nutrient: PH,
      countryCodes: [BR],
    },
    {
      id: 19,
      value: PHOSPHORUS_BRAZIL,
      displayName: getFormulaDisplayName(PHOSPHORUS_BRAZIL, language),
      nutrient: P,
      countryCodes: [BR],
    },
    {
      id: 20,
      value: POTASSIUM_BRAZIL,
      displayName: getFormulaDisplayName(POTASSIUM_BRAZIL, language),
      nutrient: K,
      countryCodes: [BR],
    },
  ];

  return userId && [1, 2, 422, 3568, 505, 750, 1180].includes(userId)
    ? [
        ...baseList,
        {
          id: 11,
          value: LIME_TRI_STATE_OH_60,
          displayName: getFormulaDisplayName(LIME_TRI_STATE_OH_60, language),
          nutrient: PH,
          countryCodes: [US],
        },
        {
          id: 12,
          value: LIME_TRI_STATE_OH_65,
          displayName: getFormulaDisplayName(LIME_TRI_STATE_OH_65, language),
          nutrient: PH,
          countryCodes: [US],
        },
        {
          id: 13,
          value: LIME_TRI_STATE_OH_68,
          displayName: getFormulaDisplayName(LIME_TRI_STATE_OH_68, language),
          nutrient: PH,
          countryCodes: [US],
        },
      ]
    : baseList;
};

export const getFieldCost = (
  prescription: PrescriptionType,
  input: InputType,
  field: FieldType,
  sum: number,
) => {
  const { acreage_unit } = field.features[0].properties;
  const inputCost = prescription.cost_per_ton || 0;
  const tons = sum / getTonSize(acreage_unit);
  const costPerAcre = (tons * inputCost) / (field.features[0].properties.acreage || 1);
  return `$${Math.round(costPerAcre)} / ${acreage_unit}`;
};

export const getNutrientName = (input: InputType) => {
  if (input.nutrient === N) {
    return 'Nitrogen';
  }
  if (input.nutrient === P) {
    return 'Phosphorus';
  }
  if (input.nutrient === K) {
    return 'Potassium';
  }
  return 'Lime';
};

export const getPrescriptionSummaryValues = (
  prescription: PrescriptionType,
  inputs: InputType[],
  field: FieldType,
  language: string,
) => {
  const input = inputs.find((input_) => input_.id === prescription.input_id);
  const formulaName = getFormulaDisplayName(prescription.formula_name, language);
  const typeName = prescription.type.replaceAll('_', ' ');
  const { acreage_unit } = field.features[0].properties;

  const averageDisplay = isNumber(prescription.field_rate_average)
    ? `${roundTwoDecimal(prescription.field_rate_average)} ${getUnitLbsAc(acreage_unit)}`
    : NO_DATA;
  const totalApplied = isNumber(prescription.total_amount_applied)
    ? `${Math.round(prescription.total_amount_applied || 0)} ${getUnitLbs(acreage_unit)}`
    : NO_DATA;
  return {
    basedOn: capitalizeEveryWord(typeName),
    expectedYield: `${prescription.expected_yield || NOT_APPLICABLE} ${getUnitBuAc(acreage_unit)}`,
    fieldCost:
      input && getFieldCost(prescription, input, field, prescription.total_amount_applied || 0),
    fieldRate: averageDisplay,
    formula: capitalizeEveryWord(formulaName),
    minimumRate: `${prescription.minimum_rate || 0} ${getUnitLbsAc(acreage_unit)}`,
    maximumRate: prescription.maximum_rate
      ? `${prescription.maximum_rate} ${getUnitLbsAc(acreage_unit)}`
      : NOT_APPLICABLE,
    totalApplied,
    input: input?.name || NOT_APPLICABLE,
    range: `${Math.round(prescription.amount_range[0] || 0)}-${Math.round(prescription.amount_range[1] || 0)} ${getUnitLbs(acreage_unit)}`,
  };
};

export const formatZonesWithOpacity = (zones: PrescriptionZoneType[]) => {
  const zoneAmounts = zones.map((zone) => zone.properties.amount);
  const max = Math.max(...zoneAmounts);
  return zones.map((zone) => ({
    ...zone,
    properties: {
      ...zone.properties,
      amount: Math.round(zone.properties.amount),
      fillPercent: zone.properties.amount / max,
    },
  }));
};

export const defaultPrescriptionName = () => {
  const d = new Date();

  return `${getMonthString(d.getMonth())} ${d.getFullYear()} - soil test`;
};

export const getPrescriptionAnalytic = (prescription: PrescriptionType) => {
  if (prescription.input.nutrient === P) {
    if (prescription.formula_name === PHOSPHORUS_SDSU_MN_BRAY) {
      return P_BRAY_ID;
    }
    if (prescription.formula_name === PHOSPHORUS_SDSU_MN_OLSEN) {
      return P_OLSEN_ID;
    }
    return PHOSPHORUS_ID;
  }
  if (prescription.input.nutrient === K) {
    if (prescription.formula_name === POTASSIUM_SDSU_MN) {
      return K_AMMONIUM_ACETATE_ID;
    }
    return POTASSIUM_ID;
  }
  if (prescription.formula_name === SATURATION_BRAZIL) {
    return BASE_SATURATION;
  }
  return PH_ID;
};

export const getSampleValueForPrescriptionAnalytic = (
  prescription: PrescriptionType,
  sample?: SampleFeatureType,
) => {
  const prescriptionAnalytic = getPrescriptionAnalytic(prescription);
  const category = prescriptionAnalytic === BASE_SATURATION ? BCSR : SOIL_ATTRIBUTES;
  return sample?.properties.analytics[category]?.[prescriptionAnalytic];
};

export const getZoneDollarsPerAcre = (
  rx: PrescriptionType,
  zone: PrescriptionZoneType,
  acreage_unit,
) => {
  // Formula: $/lb * lb/ac = $/ac
  const cost_per_ton = rx.cost_per_ton || 0;
  return (cost_per_ton / getTonSize(acreage_unit)) * zone.properties.amount;
};

export const sortZones = (zones: PrescriptionZoneType[]) =>
  zones.sort((a, b) => (a.properties.id > b.properties.id ? 1 : -1));

export const inputFilter = (input: InputType, nutrient: string, formula: string) => {
  if (input.customizeable && [LIME_ISU_60, LIME_ISU_65, LIME_ISU_69].includes(formula)) {
    return [CUSTOM_CCE_ISU, CUSTOM_RNV_ENM_ISU].includes(input.id);
  }
  if (input.customizeable && formula === LIME_SDSU) {
    return [CUSTOM_CCE_SDSU, CUSTOM_RNV_SDSU].includes(input.id);
  }
  if (
    [
      LIME_TRI_STATE_60,
      LIME_TRI_STATE_65,
      LIME_TRI_STATE_68,
      LIME_TRI_STATE_OH_60,
      LIME_TRI_STATE_OH_65,
      LIME_TRI_STATE_OH_68,
    ].includes(formula)
  ) {
    return [CUSTOM_RNV_INDIANA, CUSTOM_NV_MICHIGAN, CUSTOM_ENP_OHIO].includes(input.id);
  }
  if (formula === SATURATION_BRAZIL) {
    return input.id === CUSTOM_RNP_BRAZIL;
  }
  return input.nutrient === nutrient && input.id !== INPUT_10_34_0_20_PERCENT_ID;
};

export const getInputOptions = (inputs: InputType[], nutrient: string, formula: string) =>
  inputs
    .filter((input) => inputFilter(input, nutrient, formula))
    .map((input) => ({ id: input.id, displayName: input.name, value: input }));

export const convertCCEForSubmission = (input: InputType, value: number) => {
  if (input.unit === PERCENT) {
    return value / 100;
  }
  return value;
};

export const convertCCEForEditing = (input: InputType, value: number) => {
  if (input.unit === PERCENT) {
    return convertDecimalToPercent(value);
  }
  return value;
};

export const convertTargetValueForSubmission = (formula: string, value: number) => {
  if (formula === SATURATION_BRAZIL) {
    return value / 100;
  }
  return value;
};

export const convertTargetValueForEditing = (formula: string, value: number) => {
  if (formula === SATURATION_BRAZIL) {
    return convertDecimalToPercent(value);
  }
  return value;
};

export const getJobStatus = (complete: number | undefined, language: string) => {
  if (complete === 1) {
    return getString('success', language);
  }
  if (complete === 0) {
    return getString('incomplete', language);
  }
  if (complete === -1) {
    return getString('failed', language);
  }
  return '';
};

const getProPrescriptionMapColors = (prescription: PrescriptionType) => {
  const min = prescription.amount_range[0];
  const max = prescription.amount_range[1];

  if (min === max) {
    return [min, PRESCRIPTION_COLORS[4][1]];
  }

  const delta = prescription.amount_range[1] - prescription.amount_range[0];
  return PRESCRIPTION_COLORS.reduce(
    (acc, pair) => {
      // Set color steps as the percentile values between min and max rx amounts
      const percentage = pair[0];
      const color = pair[1];
      return [...acc, min + delta * percentage, color];
    },
    [] as (string | number)[],
  );
};

const addQuantityLayer = (map: Map, quantityId: string, mapId: string) => {
  map.getCanvas().style.cursor = 'default';
  map.addLayer({
    id: quantityId,
    type: 'symbol',
    source: mapId,
    layout: {
      'text-field': ['get', AMOUNT_KEY],
      'text-justify': 'center',
      'text-size': 12,
    },
    paint: {
      'text-color': WHITE,
    },
  });
};

const addTooltipHandler = (
  map: Map,
  mapId: string,
  setPopupInfo: (info: { lng: number; lat: number; content: React.ReactNode } | null) => void,
  getPopupContent: (amount: number) => ReactNode,
) => {
  map.getCanvas().style.cursor = 'pointer';
  map.on('mousemove', mapId, (e) => {
    if (!e.features?.length) {
      setPopupInfo(null);
      return;
    }
    const amount = e.features[0].properties?.[AMOUNT_KEY];

    if (isNumber(amount)) {
      setPopupInfo({
        ...e.lngLat,
        content: getPopupContent(amount),
      });
    }
  });
  map.on('mouseleave', mapId, () => {
    setPopupInfo(null);
  });
};

const clearTooltipHandler = (map: Map, mapId) => {
  map.on('mousemove', mapId, () => {});
};

export const addProPrescriptionToMap = (
  prescription: PrescriptionType,
  map: Map,
  mapId: string,
  setPopupInfo: (info: { lng: number; lat: number; content: React.ReactNode } | null) => void,
  getPopupContent: (amount: number) => ReactNode,
) => {
  if (!(prescription.geojson_uri && prescription.pro_density)) {
    return;
  }
  const quantityId = `${mapId}-quantities`;

  const mapColors = getProPrescriptionMapColors(prescription);

  const paint: FillPaint = {
    'fill-color': ['interpolate', ['linear'], ['get', AMOUNT_KEY], ...mapColors],
    'fill-outline-color': PRO_PRESCRIPTIONS_NO_OUTLINE_DENSITIES.includes(
      prescription.pro_density as string,
    )
      ? 'rgba(0,0,0,0)'
      : WHITE,
  };

  const shouldIncludeSymbols = !PRO_PRESCRIPTION_SYMBOL_BLACKLIST.includes(
    prescription.pro_density,
  );

  if (map.getLayer(mapId)) {
    const source = map.getSource(mapId) as GeoJSONSource;
    source.setData(prescription.geojson_uri);

    map.setPaintProperty(mapId, 'fill-color', paint['fill-color']);
    map.setPaintProperty(mapId, 'fill-outline-color', paint['fill-outline-color']);

    const quantityLayer = map.getLayer(quantityId);

    if (quantityLayer && !shouldIncludeSymbols) {
      map.removeLayer(quantityId);
      addTooltipHandler(map, mapId, setPopupInfo, getPopupContent);
    }
    if (!quantityLayer && shouldIncludeSymbols) {
      addQuantityLayer(map, quantityId, mapId);
      clearTooltipHandler(map, mapId);
    }

    return;
  }
  map.addSource(mapId, {
    type: 'geojson',
    data: prescription.geojson_uri,
  });

  map.addLayer({
    id: mapId,
    type: 'fill',
    source: mapId,
    paint,
  });

  if (shouldIncludeSymbols) {
    addQuantityLayer(map, quantityId, mapId);
  } else {
    addTooltipHandler(map, mapId, setPopupInfo, getPopupContent);
  }
};
