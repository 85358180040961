import React from 'react';
import { notifications, type NotificationData } from '@mantine/notifications';
import { MdCheck, MdClear } from 'react-icons/md';
import { NotificationProps } from '@mantine/core';

export type ToastType = 'success' | 'error';

// Omit `message` and `autoClose` from `NotificationData` to support a lot of pre-Mantine instances
interface MantineProps
  extends Omit<NotificationProps, 'onClose'>,
    Pick<NotificationData, 'id' | 'position' | 'onClose' | 'onOpen'> {}

const DEFAULT_AUTO_CLOSE_MS = 3000;
const DEFAULT_ERROR_AUTO_CLOSE_MS = 5000;

export const getToastColor = (type?: ToastType) => {
  if (type === 'success') {
    return 'green';
  }

  if (type === 'error') {
    return 'red.8';
  }
};

export const getToastIcon = (type?: ToastType) => {
  if (type === 'success') {
    return <MdCheck height={36} width={36} />;
  }

  if (type === 'error') {
    return <MdClear height={36} width={36} />;
  }
};

const getToastAutoClose = (type?: ToastType, autoClose?: boolean | number) => {
  if (typeof autoClose === 'undefined') {
    const isError = type === 'error';

    return isError ? DEFAULT_ERROR_AUTO_CLOSE_MS : DEFAULT_AUTO_CLOSE_MS;
  }

  return autoClose;
};

/**
 * Trigger a toast notification in the UI using Mantine's notification system.
 *
 * @param message Content for the body of the toast
 * @param type Sets default color and icon, which can be overridden by `mantineProps`.
 * @param autoClose Time in milliseconds before toast auto-closes, or `false` to disable auto-close.
 * See `DEFAULT_AUTO_CLOSE_MS` and `DEFAULT_ERROR_AUTO_CLOSE_MS` for default values.
 * @param mantineProps All of the native props, minus those used for supporting pre-Mantine toasts.
 */
const showToast = (
  message: React.ReactNode,
  type?: ToastType,
  autoClose?: boolean | number,
  mantineProps?: MantineProps,
) => {
  notifications.show({
    position: 'top-right',
    ...mantineProps,
    icon: mantineProps?.icon || getToastIcon(type),
    color: mantineProps?.color || getToastColor(type),
    autoClose: getToastAutoClose(type, autoClose),
    message,
  });
};

export default showToast;
