import React from 'react';
import { Flex, Text } from '@mantine/core';
import { getAcreageUnitFromLang } from 'util/units';

type Props = {
  price: number;
  language: string;
};

const AnalysisProductPrice = ({ language, price }: Props) => {
  const valueBeforeDecimal = price.toString().split('.')[0];
  const valueAfterDecimal = price.toString().split('.')[1] || '';

  return (
    <Flex align="baseline" gap={0}>
      <Text fz="3rem">${valueBeforeDecimal}</Text>
      <span>
        {valueAfterDecimal ? `.${valueAfterDecimal}` : ''} / {getAcreageUnitFromLang(language)}
      </span>
    </Flex>
  );
};

export default AnalysisProductPrice;
