import React, { useEffect, useState } from 'react';
import { Button, Group, Indicator, Menu, Tabs } from '@mantine/core';
import { DEALER_PORTAL_URL } from 'constants/agency';
import { labAppLinkConfig, navbarLinkConfig } from 'constants/navbarLinks';
import { MY_ACCOUNTS, NONE } from 'constants/variables';
import Plus from 'images/icons/plus.png';
import { FiChevronDown, FiExternalLink } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { Link, useMatch, useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import { getString } from 'strings/translation';
import { getIsPatternSite, hostNameLogoParse } from 'util/auth';
import useBroswerLanguage from 'util/hooks/useLanguage';
import {
  userIsAdmin,
  userIsAgencyAdmin,
  userIsAgent,
  userIsLab,
  userIsOperator,
  userIsSampler,
  userIsSuperAdmin,
} from 'util/userRoles';

import { useQuery } from '@tanstack/react-query';
import { FONT_WEIGHT_BOLD } from 'constants/mantine';
import { collectionGroupQueryOptions } from 'util/queryOptions';
import styles from './NavBar.module.css';

interface NavBarProps {
  mode: 'core' | 'lab';
}

const NavBar = ({ mode }: NavBarProps) => {
  const navigate = useNavigate();
  const isPattern = getIsPatternSite();
  const [logo, setLogo] = useState<string | null>(null);
  const { firstName, lastName, roles, operationsCount, agencies, canScan } = useSelector(
    (state: RootState) => ({
      firstName: state.user.currentUser?.first_name || '',
      lastName: state.user.currentUser?.last_name || '',
      roles: state.user.currentUser?.roles || [],
      operationsCount:
        state.operations.summary.items.length ||
        Object.keys(state.operations.operationsById).length,
      agencies: state.user.currentUser?.agencies || [],
      canScan: state.user.currentUser?.can_scan || false,
    }),
  );
  const isAdmin = userIsAdmin(roles);
  const isLab = userIsLab(roles);
  const isSuperAdmin = userIsSuperAdmin(roles);
  const isAgencyAdmin = userIsAgencyAdmin(roles);
  const isAgent = userIsAgent(roles);
  const isOperator = userIsOperator(roles);
  const isSampler = userIsSampler(roles);
  const language = useBroswerLanguage();

  const showDataReviewTopLevel = Boolean(
    canScan || ((isAgent || isAgencyAdmin) && agencies[0]?.self_scanning),
  );

  const navLinks =
    mode === 'core'
      ? navbarLinkConfig(
          isAdmin,
          isLab,
          isSuperAdmin,
          isAgencyAdmin,
          isAgent,
          isSampler,
          operationsCount,
          agencies,
          language,
          showDataReviewTopLevel,
        )
      : labAppLinkConfig(isSuperAdmin, language);

  const isLabRoute = useMatch('/lab/*');
  const match = useMatch('/*');

  useEffect(() => {
    const logoFromUrl = hostNameLogoParse();
    if (logoFromUrl) {
      setLogo(logoFromUrl);
    }
  }, []);

  const collectionsGroupQuery = useQuery({
    ...collectionGroupQueryOptions(),
    enabled: showDataReviewTopLevel,
  });

  const getActiveLink = () => {
    if (isLabRoute) {
      const foundRoute = navLinks.topLevelNav
        .filter((link) => link.link)
        .find((link) => isLabRoute.pathname.startsWith(link.link || ''))?.name;
      return foundRoute || NONE;
    }
    const foundRoute = navLinks.topLevelNav.find(
      (link) => match?.pathname.split('/')[1] === link.link?.split('/')[1],
    )?.name;
    return foundRoute || NONE;
  };

  const openDealerPortal = () => {
    // Webflow https://webflow.com/dashboard/sites/pattern-ag-new/code added code to parse in head code
    window.open(DEALER_PORTAL_URL, '_blank');
  };

  const topBarLinks = () => (
    <Tabs defaultValue={MY_ACCOUNTS} value={getActiveLink()}>
      <Tabs.List>
        {navLinks.topLevelNav
          .filter((link) => link.link && link.show)
          .map((link, index) => {
            const tab = (
              <Tabs.Tab
                value={link.name}
                key={`link-${link.link}`}
                data-test-id={`navbar-link-text-${index}`}
                onClick={() => {
                  navigate(link.link || '/');
                }}
              >
                {link.name}
              </Tabs.Tab>
            );
            if (link.link === '/data-review' && collectionsGroupQuery.data?.operations.length) {
              return (
                <Indicator
                  color="#14b929"
                  fw={FONT_WEIGHT_BOLD}
                  key={`indicator-${link.link}`}
                  label="!"
                  size={18}
                  styles={() => ({
                    indicator: {
                      top: '15%',
                      right: '10%',
                    },
                  })}
                >
                  {tab}
                </Indicator>
              );
            }
            return tab;
          })}
      </Tabs.List>
    </Tabs>
  );

  const topButtonDropDowns = () =>
    navLinks.topLevelNav
      .filter((link) => !link.link && link.dropdownLinks && link.show)
      .map((link, index) => {
        return (
          <Menu shadow="md" key={link.name}>
            <Menu.Target>
              <Button
                variant="outline"
                data-test-id={`dropdown-${index}`}
                rightSection={<FiChevronDown />}
              >
                {link.name}
              </Button>
            </Menu.Target>
            <Menu.Dropdown>
              {link.dropdownLinks?.map((l, dropdownIndex) => (
                <Menu.Item
                  key={dropdownIndex}
                  onClick={() => {
                    navigate(l.link);
                  }}
                  data-test-id={`dropdown-link-${index}-${dropdownIndex}`}
                >
                  {l.name}
                </Menu.Item>
              ))}
            </Menu.Dropdown>
          </Menu>
        );
      });

  return (
    <div className={styles.Wrapper}>
      <Group>
        {logo && (
          <Link to="/">
            <img
              alt="Pattern Logo"
              className={styles.Logo}
              data-test-id="pattern-logo"
              src={logo}
            />
          </Link>
        )}
        {isPattern && (isAgencyAdmin || isAgent || isOperator) && agencies[0]?.agency_logo_url && (
          <>
            <img alt="Plus" className={styles.Plus} src={Plus} />
            <img
              alt="Agency Logo"
              className={styles.AgencyLogo}
              src={agencies[0].agency_logo_url}
            />
          </>
        )}
        {topBarLinks()}
        {topButtonDropDowns()}
      </Group>
      <Group justify="flex-end">
        {(isSuperAdmin || isAdmin || isAgent || isAgencyAdmin) && (
          <Button
            variant="outline"
            rightSection={<FiExternalLink size={14} />}
            onClick={openDealerPortal}
          >
            {getString('dealerPortal', language)}
          </Button>
        )}
        <Menu shadow="md" data-test-id="account-name">
          <Menu.Target>
            <Button variant="outline" data-test-id="account-name" rightSection={<FiChevronDown />}>
              {firstName} {lastName}
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            {navLinks.dropdownLinks
              .filter((b) => b.show === true)
              .map((b, index) => (
                <Menu.Item
                  key={`${b.link}-${b.name}`}
                  onClick={() => {
                    navigate(b.link);
                  }}
                  data-test-id={`button-${index}`}
                >
                  {b.name}
                </Menu.Item>
              ))}
          </Menu.Dropdown>
        </Menu>
      </Group>
    </div>
  );
};

export default NavBar;
