import { Menu } from '@mantine/core';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import showToast from 'actions/toastActions';
import { THREE_SECONDS, FIVE_MINUTES } from 'constants/time';
import React from 'react';
import { getLabIntakeForm, putLabIntakeForm } from 'store/samplePlans/requests';
import { getString } from 'strings/translation';
import { samplingPlansQueryKeys } from 'util/queryKeys';
import { downloadViaAnchorElem } from 'util/request';

type Props = {
  language: string;
  samplingPlanId: number;
};

const LabIntakeMenuItem = ({ samplingPlanId, language }: Props) => {
  const queryClient = useQueryClient();
  const queryKey = samplingPlansQueryKeys.externalLab(samplingPlanId);

  const mutation = useMutation({
    mutationFn: () => putLabIntakeForm(samplingPlanId),
    onError: (err) => showToast(err.message, 'error'),
    onSuccess: () => {
      queryClient.setQueryDefaults(queryKey, {
        refetchInterval: THREE_SECONDS,
        staleTime: 0,
      });

      queryClient.invalidateQueries({ queryKey });
    },
  });

  const query = useQuery({
    queryKey,
    queryFn: () => getLabIntakeForm(samplingPlanId),
    refetchInterval: ({ state: { data = {} } }) => {
      if (data?.complete === 0) {
        return THREE_SECONDS;
      }

      return data.complete === 1 && data.download_url ? false : FIVE_MINUTES;
    },
    staleTime: ({ state: { data = {} } }) => {
      if (data?.complete === 0) {
        return 0;
      }

      return data.complete === 1 && data.download_url ? Infinity : FIVE_MINUTES;
    },
  });

  const hasNotBeenInitiated = Object.keys(query.data || {}).length === 0;
  const hasBeenInitiated = query.data?.complete === 0;
  const hadIssueWithExternalLab = query.data?.complete === -1;

  const shouldDisable =
    query.isPending || mutation.isPending || hasBeenInitiated || hadIssueWithExternalLab;

  const getText = () => {
    // The query itself is pending
    if (query.isPending) {
      return `${getString('loading', language)}...`;
    }

    // The PUT has been fired at some point, but comms with external lab are still in progress
    if (hasBeenInitiated) {
      return getString('creatingLabIntakeForm', language);
    }

    // PUT has never been fired
    if (hasNotBeenInitiated) {
      return getString('createLabIntakeForm', language);
    }

    // Comms with external lab are complete but something went wrong
    if (hadIssueWithExternalLab) {
      return `${getString('creatingLabIntakeFormError', language)}...`;
    }

    return getString('downloadLabIntakeForm', language);
  };

  const handleClick = () => {
    if (query.data?.download_url) {
      downloadViaAnchorElem(query.data.download_url, `lab_intake_form_${samplingPlanId}.csv`);
    } else if (hasNotBeenInitiated) {
      mutation.mutate();
    }
  };

  return (
    <Menu.Item disabled={shouldDisable} onClick={handleClick}>
      {getText()}
    </Menu.Item>
  );
};

export default LabIntakeMenuItem;
