import React from 'react';
import { Box, Text } from '@mantine/core';

const SamplingZonesStep = () => {
  return (
    <Box px="xl" py="md" ta="center">
      <Text fz="h2" fw="bold" component="h2" mb="sm">
        Set Sampling Zones
      </Text>
      pretend there's a map and controls...
    </Box>
  );
};

export default SamplingZonesStep;
