import { AppThunk } from 'store';
import {
  receiveCatalogs,
  receiveSingleCatalog,
  catalogRequestError,
  requestCatalogs,
} from './actions';
import {
  requestGetCatalogs,
  requestGetCatalog,
  requestPutCatalog,
  requestPostCatalog,
} from './requests';
import { CatalogType } from './types';

export const getAllCatalogs =
  (operationId?: number, showInUi?: boolean, name?: string, all?: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(requestCatalogs());
    try {
      const response = await requestGetCatalogs(operationId, showInUi, name, all);
      return dispatch(receiveCatalogs(response));
    } catch (e) {
      return dispatch(catalogRequestError);
    }
  };

export const getCatalog =
  (catalogId: number): AppThunk =>
  async (dispatch) => {
    dispatch(requestCatalogs());
    try {
      const response = await requestGetCatalog(catalogId);
      return dispatch(receiveSingleCatalog(response));
    } catch (e) {
      return dispatch(catalogRequestError);
    }
  };

export const createCatalog =
  (catalog: Partial<CatalogType>): AppThunk =>
  async (dispatch) => {
    dispatch(requestCatalogs());
    try {
      const response = await requestPostCatalog(catalog);
      dispatch(receiveSingleCatalog(response));
      return response;
    } catch (e) {
      dispatch(catalogRequestError);
    }
  };

export const updateCatalog =
  (catalogId: number, catalog: Partial<CatalogType>): AppThunk =>
  async (dispatch) => {
    dispatch(requestCatalogs());
    try {
      const response = await requestPutCatalog(catalogId, catalog);
      dispatch(receiveSingleCatalog(response));
      return response;
    } catch (e) {
      dispatch(catalogRequestError);
    }
  };
