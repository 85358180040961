import React, { useState } from 'react';
import { Select } from '@mantine/core';

type Props = {
  label: string;
  onChange: (value: string) => void;
};

type AnalysisProduct = '360_COMPLETE';

const options: { label: string; value: AnalysisProduct }[] = [
  {
    label: '360 Complete',
    value: '360_COMPLETE',
  },
];

const AnalysisProductSelect = ({ label, onChange }: Props) => {
  const [product, setProduct] = useState<AnalysisProduct>('360_COMPLETE');

  return (
    <Select
      data={options}
      label={label}
      value={product}
      onChange={(value) => {
        if (value) {
          setProduct(value as AnalysisProduct);
          onChange(value);
        }
      }}
    />
  );
};

export default AnalysisProductSelect;
