import React from 'react';
import { Group, Stack, Title, Divider, Button } from '@mantine/core';
import useFieldGeometry from 'util/hooks/useFieldGeometry';
import { getAcreageUnitFromLang } from 'util/units';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { roundTwoDecimal } from 'util/numUtils';
import { getString } from 'strings/translation';

const ZoneAnalysisHeader = () => {
  const language = useBroswerLanguage();
  const fieldGeometry = useFieldGeometry();
  const { name, acreage } = fieldGeometry.features[0].properties;
  const headerTitleName = `${name} (${roundTwoDecimal(acreage)} ${getAcreageUnitFromLang(language)})`;
  const headerTitle = `${headerTitleName} - ${getString('editSamplePlan', language)}`;

  return (
    <Stack gap={0}>
      <Group justify="space-between">
        <Title order={2}>{headerTitle}</Title>
        <Group>
          <Button>{getString('orderSamplePlan', language)}</Button>
          <Button variant="outline">{getString('cancel', language)}</Button>
        </Group>
      </Group>
      <Divider my="md" size="sm" />
    </Stack>
  );
};

export default ZoneAnalysisHeader;
