import React, { useState } from 'react';
import { Stepper, Button, Group, Stack, Divider } from '@mantine/core';
import SamplePlanDetailsSection from './SamplePlanDetailsSection';
import AnalysisSamplingSection from './AnalysisSamplingSection';
import SamplingZonesStep from './SamplingZonesStep';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import ReviewOrderStep from './ReviewOrderStep';
import SamplingSection from './SamplingSection';

const getNavButtonsText = (): Record<number, { previous: string | null; next: string }> => ({
  0: {
    previous: null,
    next: 'Set sampling zones',
  },
  1: {
    previous: 'Analysis & sampling',
    next: 'Review order',
  },
  2: {
    previous: 'Sampling zones',
    next: 'Confirm order',
  },
});

const SampleOrderSteps = () => {
  const [activeStepNumber, setActiveStepNumber] = useState(0);
  const navButtonsConfig = getNavButtonsText()[activeStepNumber];
  const { previous, next } = navButtonsConfig;
  const isFinalStep = activeStepNumber === Object.keys(getNavButtonsText()).length - 1;

  const handleStepChange = (nextStep: number) => {
    setActiveStepNumber(nextStep);
  };

  return (
    <Stack>
      <Stepper
        active={activeStepNumber}
        onStepClick={handleStepChange}
        w={1200}
        mx="auto"
        h="100%"
        mb={60}
      >
        <Stepper.Step allowStepSelect label="Analysis & Sampling">
          <Stack maw={800} mx="auto">
            <SamplePlanDetailsSection />
            <Divider my="xs" />
            <AnalysisSamplingSection />
            <Divider my="xs" />
            <SamplingSection />
          </Stack>
        </Stepper.Step>
        <Stepper.Step allowStepSelect label="Sampling Zones">
          <SamplingZonesStep />
        </Stepper.Step>
        <Stepper.Step allowStepSelect label="Confirm Order">
          <ReviewOrderStep />
        </Stepper.Step>
      </Stepper>
      <Group
        bg="var(--mantine-color-body)"
        bottom={0}
        justify="center"
        pos="sticky"
        py="md"
        style={{
          borderTopWidth: 1,
          borderTopStyle: 'solid',
          borderTopColor: 'var(--mantine-color-gray-3)',
        }}
      >
        {!!previous && (
          <Button
            size="lg"
            leftSection={<FaArrowLeft width={32} style={{ padding: 0 }} />}
            variant="default"
            onClick={() => handleStepChange(activeStepNumber - 1)}
          >
            {previous}
          </Button>
        )}
        <Button
          size="lg"
          rightSection={isFinalStep ? null : <FaArrowRight width={32} style={{ padding: 0 }} />}
          onClick={() => handleStepChange(activeStepNumber + 1)}
        >
          {next}
        </Button>
      </Group>
    </Stack>
  );
};

export default SampleOrderSteps;
