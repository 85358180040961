import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'; // uncomment to use this

import 'normalize.css';

import { isProduction } from 'util/request';

import configureStore from './store';
import AppV2 from './App';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 300000, // fresh for 5 minutes
    },
  },
});

Sentry.init({
  dsn: 'https://d089779fc2330ebe14a5c3c62482353d@o4505682002509824.ingest.sentry.io/4506033305812992',
  enabled: isProduction,
  integrations: [new Integrations.BrowserTracing(), new Sentry.Replay()],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={configureStore()}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AppV2 />
      </BrowserRouter>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      {/* ☝️ Uncomment to use ☝️ */}
    </QueryClientProvider>
  </Provider>,
);
