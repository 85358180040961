import React from 'react';
import { Stack, Text, Group, Input, Switch, TextInput } from '@mantine/core';
import { CatalogType } from 'store/catalogs/types';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { AdminAccess, UserSearch } from 'common';
import { UserSelectorType } from 'store/user/types';

type SetAttributesPropsType = {
  catalog: Partial<CatalogType>;
  onValueChange: (attributeKey: keyof CatalogType, newValue: string | number | boolean) => void;
};

const SetAttributes = ({ catalog, onValueChange }: SetAttributesPropsType) => {
  const language = useBroswerLanguage();

  const handleUserSelection = (user: UserSelectorType) => {
    const { user_id, first_name, last_name } = user;
    onValueChange('user_id', user_id);
    onValueChange('user_name', `${first_name} ${last_name}`);
  };

  return (
    <Stack mr="xl" mb="xl">
      <Group justify="space-between" align="flex-start" gap="xl">
        <Group justify="flex-start" align="flex-start" gap="xl">
          <Input.Wrapper label={getString('name', language)}>
            <TextInput
              onChange={(event) => onValueChange('name', event.currentTarget.value)}
              value={catalog.name || ''}
            />
          </Input.Wrapper>
          <AdminAccess>
            <Input.Wrapper label={getString('user', language)}>
              <UserSearch
                placeholder={getString('searchByUser', language)}
                onSelect={(user) => handleUserSelection(user)}
                defaultValue={catalog.user_name}
              />
            </Input.Wrapper>
          </AdminAccess>
          <Input.Wrapper label={getString('createdBy', language)}>
            <Text>{catalog.created_by_name}</Text>
          </Input.Wrapper>
        </Group>
        <Input.Wrapper label={getString('active', language)}>
          <Switch
            checked={catalog.show_in_ui}
            onChange={() => onValueChange('show_in_ui', !catalog?.show_in_ui)}
            mt="0.25rem"
          />
        </Input.Wrapper>
      </Group>
    </Stack>
  );
};

export default SetAttributes;
