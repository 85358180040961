import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { Table, Text } from '@mantine/core';
import { LoadingRows } from 'common';
import { RootState } from 'store';
import { getOrderHistory } from 'store/orderHistory/thunks';
import { OrderHistoryRow } from './OrderHistoryRow';
import { OrderHistoryStatusFilter } from 'store/orderHistory/types';

type OrderHistoryTableProps = {
  operationName?: string | null;
  year: number;
  planStatus: OrderHistoryStatusFilter;
};

export const OrderHistoryTable = ({ operationName, year, planStatus }: OrderHistoryTableProps) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();

  const { orderHistory, isFetching } = useSelector((state: RootState) => ({
    orderHistory: state.orderHistory.paginated.items,
    isFetching: state.orderHistory.isFetching,
  }));

  useEffect(() => {
    dispatch(getOrderHistory(year, operationName));
  }, [year, operationName]);

  const allOrdersShown = planStatus === 'active-and-completed';

  const ordersToUse = allOrdersShown
    ? orderHistory
    : orderHistory.filter((o) =>
        o.fields.some((f) => f.sampling_plans.some((p) => p.status === 'completed')),
      );

  return (
    <Table>
      <Table.Tbody>
        <Table.Tr>
          <Table.Th w="20%">
            <Text inherit>{getString('operation', language)}</Text>
            <Text inherit fs="italic">{`${getString('panelsOrdered', language)}`}</Text>
          </Table.Th>
          <Table.Th w="15%" ta="right">
            <Text inherit>{getString('acres', language)}</Text>
            <Text inherit fs="italic">{`${getString('samples', language)}`}</Text>
          </Table.Th>
          <Table.Th w="20%" ta="right">
            {getString('samplePlan', language)}
          </Table.Th>
          <Table.Th w="20%" ta="right">
            {getString('planStatus', language)}
          </Table.Th>
          <Table.Th w="15%" ta="right">
            {getString('purchases', language)}
          </Table.Th>
          <Table.Th w="15%" ta="right">
            {getString('earnings', language)}
          </Table.Th>
          <Table.Th w="15%" ta="right">
            {getString('balance', language)}
          </Table.Th>
          <Table.Th w="3rem" maw="3rem" miw="3rem" />
        </Table.Tr>
        {isFetching && <LoadingRows colSpan={7} rowSpan={10} />}
        {!isFetching &&
          ordersToUse.map((o) => (
            <OrderHistoryRow key={o.id} operation={o} planStatus={planStatus} />
          ))}
        {!isFetching && ordersToUse.length === 0 && (
          <Table.Tr>
            <Table.Td colSpan={7} ta="center">
              <Text inherit fz="h5" my="lg">
                {getString(
                  allOrdersShown ? 'noOrderHistoryMsg' : 'noOrderHistoryCompletedMsg',
                  language,
                )}
              </Text>
            </Table.Td>
          </Table.Tr>
        )}
      </Table.Tbody>
    </Table>
  );
};
