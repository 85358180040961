import React from 'react';
import { Space, Stack } from '@mantine/core';
import ZoneAnalysisHeader from './Components/Header';
import SampleOrderSteps from './Components/steps/SampleOrderSteps';

const ZoneAnalysis = () => {
  return (
    <Stack>
      <Space h="md" />
      <ZoneAnalysisHeader />
      <SampleOrderSteps />
    </Stack>
  );
};

export default ZoneAnalysis;
