import React from 'react';
import { Box, Text } from '@mantine/core';

const ReviewOrderStep = () => {
  return (
    <Box px="xl" py="md" ta="center">
      <Text fz="h2" fw="bold" component="h2" mb="sm">
        Review Order
      </Text>
      Press button, buy now!
    </Box>
  );
};

export default ReviewOrderStep;
