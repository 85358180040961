import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, ActionIcon, Group, Space, Select, Text, Loader } from '@mantine/core';
import { FiPrinter } from 'react-icons/fi';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { OperationType } from 'store/operation/types';
import { FieldType, RiskMapboxSample, SamplingPlanType } from 'store/fields/types';
import { AnalyticType } from 'store/analytics/types';
import {
  CROP_PROTECTION,
  SUMMARY,
  ANALYTIC_DETAILS,
  FIELD_COMPARISON,
  ZONE_DETAILS,
  COMPACTION_ID,
} from 'constants/results';
import classNames from 'classnames';
import { capitalizeEveryWord } from 'util/stringUtils';
import { getPlanName } from 'util/samplePlan';
import { getAnalytic } from 'util/results';
import { RecommendationType } from 'store/recommendations/types';
import { getLatestCropPlan } from 'util/cropPlans';

import SingleAnalytic from './SingleAnalytic/SingleAnalytic';
import AnalyticCategoryList from './AllAnalytics/AnalyticCategoryList';
import AnalyticBar from '../../common/AnalyticBar';
import styles from './Container.module.css';
import CompactionAnalytic from './SingleAnalytic/CompactionAnalytic';

type PanelPropsType = {
  operation: OperationType;
  field: FieldType;
  samplingPlan: SamplingPlanType;
  activeAnalytic: AnalyticType;
  samples: RiskMapboxSample[];
  isFieldsLoading: boolean;
  analyticCategories: string[];
  analytics: AnalyticType[];
  selectedField: number | undefined;
  setNewField: (value: any) => void;
  samplingPlans: SamplingPlanType[];
  recommendations: RecommendationType[];
};

const PanelContainer = ({
  operation,
  field,
  samplingPlan,
  activeAnalytic,
  samples,
  isFieldsLoading,
  analyticCategories,
  analytics,
  selectedField,
  setNewField,
  samplingPlans,
  recommendations,
}: PanelPropsType) => {
  const language = useBroswerLanguage();
  const [showAllAnalytics, toggleShowAllAnalytics] = useState(false);
  const [analyticAccordionsOpen, setAnalyticAccordionsOpen] = useState<string[]>([SUMMARY]);
  const navigate = useNavigate();
  const cropPlan = getLatestCropPlan(field);
  const fieldOptions = operation.fields.map((opField) => ({
    id: opField.id,
    label: opField.name,
    value: String(opField.id),
  }));

  const planAnalytic = getAnalytic(samplingPlan, activeAnalytic.category, activeAnalytic.id);
  const isCompaction = activeAnalytic.id === COMPACTION_ID;

  const handlePrint = () => {
    setAnalyticAccordionsOpen([SUMMARY, ANALYTIC_DETAILS, FIELD_COMPARISON, ZONE_DETAILS]);
    // wait for state change to propagate
    setTimeout(() => {
      window.print();
      setAnalyticAccordionsOpen([]);
    }, 600);
  };

  const toggleAccordion = (accordionName: string) => {
    setAnalyticAccordionsOpen(
      analyticAccordionsOpen.includes(accordionName) ? [] : [accordionName],
    );
  };

  const displaySamplePlans = samplingPlans.map((plan) => ({
    id: plan.id,
    label: getPlanName(plan),
    value: String(plan.id),
  }));

  return (
    <div className={styles.PanelWrapper}>
      <Button
        variant="outline"
        onClick={() => {
          navigate(
            `/results/field/${operation.id}/${samplingPlan.field_id}/${samplingPlan.id}/${CROP_PROTECTION}?crop=${cropPlan?.crop}`,
          );
        }}
        className={styles.HideForPrint}
      >
        {getString('backToFieldSummary', language)}
      </Button>
      <Space h="xl" />
      <Group justify="space-between">
        <Group justify="flex-start">
          <Select
            onChange={(val) => setNewField(val)}
            value={fieldOptions.find((fiel) => fiel.id === selectedField)?.value}
            data={fieldOptions}
          />
          {selectedField !== samplingPlan.field_id && <Loader size="sm" />}
        </Group>
        <ActionIcon
          radius="xl"
          size={40}
          variant="outline"
          onClick={handlePrint}
          className={classNames(styles.DownloadButton, styles.HideForPrint)}
        >
          <FiPrinter />
        </ActionIcon>
      </Group>
      <Space h="sm" />
      {!showAllAnalytics && planAnalytic ? (
        <>
          <Group justify="space-between" className={styles.HideForPrint}>
            <Text
              className={styles.Hover}
              size="lg"
              fw={500}
              c="blue.3"
              onClick={() => toggleShowAllAnalytics(true)}
            >
              {`< ${getString('allResults', language)}`}
            </Text>
            <Select
              onChange={(val) =>
                navigate(
                  `/results/analytic/${operation.id}/${samplingPlan.field_id}/${val}/${activeAnalytic.id}`,
                )
              }
              value={displaySamplePlans.find((sp) => sp.id === Number(samplingPlan.id))?.value}
              data={displaySamplePlans}
            />
          </Group>
          <Space h="xl" />
          <AnalyticBar textSize="sm" activeAnalytic={activeAnalytic} samplingPlan={samplingPlan} />
          <div className={styles.BottomBorder} />
          {isCompaction ? (
            <CompactionAnalytic
              activeAnalytic={activeAnalytic}
              analyticAccordionsOpen={analyticAccordionsOpen}
              field={field}
              operation={operation}
              planAnalytic={planAnalytic}
              recommendations={recommendations}
              samplingPlan={samplingPlan}
              toggleAccordion={toggleAccordion}
            />
          ) : (
            <SingleAnalytic
              operation={operation}
              field={field}
              samplingPlan={samplingPlan}
              activeAnalytic={activeAnalytic}
              samples={samples}
              isFieldsLoading={isFieldsLoading}
              analyticAccordionsOpen={analyticAccordionsOpen}
              toggleAccordion={toggleAccordion}
              planAnalytic={planAnalytic}
              analyticCategories={analyticCategories}
              recommendations={recommendations}
            />
          )}
        </>
      ) : (
        <>
          <Space h="md" />
          <Group justify="flex-end" className={styles.AnalyticResult}>
            <Text
              className={styles.Hover}
              size="lg"
              c="blue.3"
              fw={700}
              onClick={() => toggleShowAllAnalytics(false)}
            >
              {`${capitalizeEveryWord(activeAnalytic.name)} >`}
            </Text>
          </Group>
          <Space h="md" />
          <AnalyticCategoryList
            samplingPlan={samplingPlan}
            analytics={analytics}
            operation={operation}
            cropPlan={cropPlan}
          />
        </>
      )}
    </div>
  );
};

export default PanelContainer;
