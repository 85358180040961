import React, { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Group, rem, Space, Stack, Text } from '@mantine/core';
import { Header } from 'common/Components/Mantine/Header';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { MergeGrid } from './MergeGrid';
import { requestMergePlates } from 'store/plates/requests';
import showToast from 'actions/toastActions';
import { PlateType } from 'store/plates/types';
import { useElementSize } from '@mantine/hooks';

const maxPlates = 4;

export const PlatesMergeContainer = () => {
  const language = useBroswerLanguage();
  const navigate = useNavigate();
  const { ref, width } = useElementSize();

  const { plateId: plateBarcode } = useParams<{ plateId: string }>();
  const [primaryBarcode, setPrimaryBarcode] = useState<string | undefined>(plateBarcode);

  const [secondaryMap, setSecondaryMap] = useState<
    {
      barcode: string | undefined;
      componentKey: number;
    }[]
  >([
    {
      barcode: undefined,
      componentKey: 1,
    },
  ]);
  const secondaryBarcodes = secondaryMap.map((plate) => plate.barcode);
  const [mostRecent, setMostRecent] = useState<string[]>([]);
  const [error, setError] = useState<string | undefined>();

  const handlePrimarySelect = (value: string | undefined) => {
    setPrimaryBarcode(value);
    setMostRecent(filteredSecondaries);
    if (value) {
      navigate(`/lab/plates/merge/${value}`);
    }
  };

  const handleSecondarySelect = (value: string | undefined, index: number) => {
    if (!value || !secondaryBarcodes.includes(value)) {
      setSecondaryMap((current) =>
        current.map((plate, idx) => ({
          barcode: idx === index ? value : plate.barcode,
          componentKey: plate.componentKey,
        })),
      );
      setMostRecent(value ? [value] : []);
    }
  };

  const increaseSecondary = () => {
    setSecondaryMap((current) => {
      const lastId = current.length ? current[current.length - 1].componentKey : 1;
      return current.length <= maxPlates
        ? current.concat([
            {
              barcode: undefined,
              componentKey: lastId + 1,
            },
          ])
        : current;
    });
  };

  const removeSecondary = (index: number) => {
    setMostRecent((current) => current.filter((b) => b !== secondaryBarcodes[index]));
    setSecondaryMap((current) =>
      current.length === 1
        ? [
            {
              barcode: undefined,
              componentKey: 1,
            },
          ]
        : current.filter((_, idx) => idx !== index),
    );
  };

  const handleMerge = async (temporary: boolean = true) => {
    if (submittable) {
      try {
        const response = await requestMergePlates(primaryBarcode, filteredSecondaries, temporary);
        setError(undefined);
        if (temporary) {
          return response as PlateType;
        }
        return null;
      } catch (e) {
        setError(e.message);
        showToast(e.message, 'error');
      }
    }
    return null;
  };

  const submit = async () => {
    if (submittable) {
      await handleMerge(false);
      navigate(`/lab/plates/details/${plateBarcode}`);
    }
  };

  const filteredSecondaries = useMemo(
    () => secondaryBarcodes.filter((b) => b !== undefined) as string[],
    [secondaryBarcodes],
  );
  const alreadySelected = [primaryBarcode, ...secondaryBarcodes].filter(
    (b) => b !== undefined,
  ) as string[];
  const submittable = primaryBarcode && filteredSecondaries && filteredSecondaries.length;

  return (
    <Stack>
      <Header title={getString('mergePlates', language)} />
      <Stack align="center">
        <Group justify="space-between" w={rem(width)}>
          <Space />
          {error && <Text c="red.5">{error}</Text>}
          <Group justify="flex-end">
            <Button onClick={submit} disabled={!submittable && !error}>
              {getString('submit', language)}
            </Button>
            <Button
              variant="filled"
              color="red.8"
              onClick={() => navigate(`/lab/plates/details/${plateBarcode}`)}
            >
              {getString('cancel', language)}
            </Button>
          </Group>
        </Group>
        <Group justify="flex-start" align="flex-start" ref={ref}>
          <Stack w="32rem">
            <MergeGrid
              barcode={primaryBarcode}
              setBarcode={handlePrimarySelect}
              onMerge={handleMerge}
              secondaryBarcodes={filteredSecondaries}
              label={getString('primary', language)}
              error={Boolean(error)}
            />
          </Stack>
          <Stack w="32rem" align="flex-end">
            {secondaryMap.map(({ barcode, componentKey }, idx) => (
              <MergeGrid
                key={componentKey}
                barcode={barcode as string | undefined}
                setBarcode={(value: string | undefined) => handleSecondarySelect(value, idx)}
                onMerge={handleMerge}
                onRemove={idx ? () => removeSecondary(idx) : undefined}
                label={`${getString('donorPlate', language)} ${idx + 1}`}
                error={Boolean(error && barcode && mostRecent.includes(barcode as string))}
                alreadySelected={alreadySelected}
              />
            ))}
            {secondaryBarcodes.length <= maxPlates && (
              <Button variant="subtle" c="blue.5" onClick={increaseSecondary} w="12rem">
                {`+ ${getString('addPlate', language)}`}
              </Button>
            )}
          </Stack>
        </Group>
      </Stack>
    </Stack>
  );
};
