import React from 'react';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { Table, Text } from '@mantine/core';
import { CollapsibleRow } from 'common';
import { OrderHistoryStatusFilter, OrderHistoryType } from 'store/orderHistory/types';
import { getOrderHistoryTotals } from 'util/orders';
import { numsToInvoiceDollars } from 'util/stringUtils';
import { OrderHistoryExpanded } from './OrderHistoryExpanded';

type OrderHistoryRowPropsType = {
  operation: OrderHistoryType;
  planStatus: OrderHistoryStatusFilter;
};

export const OrderHistoryRow = ({ operation, planStatus }: OrderHistoryRowPropsType) => {
  const language = useBroswerLanguage();
  const allPlans = operation.fields.flatMap((field) => field.sampling_plans);
  const { total, collection, commission } = getOrderHistoryTotals(...allPlans);
  return (
    <CollapsibleRow
      collapseComponent={<OrderHistoryExpanded operation={operation} planStatus={planStatus} />}
    >
      <Table.Td>
        <Text inherit>{operation.name}</Text>
      </Table.Td>
      <Table.Td ta="right">
        <Text inherit>{getString('viewDetails', language)}</Text>
      </Table.Td>
      <Table.Td ta="right">
        <Text inherit>{getString('viewDetails', language)}</Text>
      </Table.Td>
      <Table.Td ta="right">
        <Text inherit>{getString('viewDetails', language)}</Text>
      </Table.Td>
      <Table.Td ta="right">
        <Text inherit>{numsToInvoiceDollars(collection)}</Text>
      </Table.Td>
      <Table.Td ta="right">
        <Text inherit>{numsToInvoiceDollars(commission)}</Text>
      </Table.Td>
      <Table.Td ta="right">
        <Text inherit>{numsToInvoiceDollars(total)}</Text>
      </Table.Td>
    </CollapsibleRow>
  );
};
