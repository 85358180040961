import React from 'react';
import { Table, Group, Text, Stack, Button, Center, Loader } from '@mantine/core';
import { SkuType } from 'store/pricing/types';
import AddSkuModal from './AddSkuModal';
import { useDisclosure } from '@mantine/hooks';
import { FONT_WEIGHT_BOLD } from 'constants/mantine';
import { useMutation, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { createSku } from 'store/pricing/requests';
import showToast from 'actions/toastActions';
import { skusQueryKeys } from 'util/queryKeys';
import { LIGHT_GREY_BG } from 'constants/colors';

interface Props {
  skuQuery: UseQueryResult<SkuType[], Error>;
}

const SkuTable = ({ skuQuery }: Props) => {
  const [opened, { open, close }] = useDisclosure(false);

  const queryClient = useQueryClient();
  const queryKey = skusQueryKeys.allSkus;

  const mutation = useMutation({
    mutationFn: (sku: Partial<SkuType>) => createSku(sku),
    onError: (err) => showToast(err.message, 'error'),
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
  });

  const isProductInSku = (product: boolean) => (product ? 'Yes' : 'No');

  if (skuQuery.isLoading) {
    return (
      <Center h="40vh">
        <Loader />
      </Center>
    );
  }

  const rows = skuQuery.data?.map((sku) => (
    <Table.Tr key={sku.id} bg={LIGHT_GREY_BG}>
      <Table.Td>{sku.name}</Table.Td>
      <Table.Td>{sku.name_pt}</Table.Td>
      <Table.Td>{sku.netsuite_name}</Table.Td>
      <Table.Td>{sku.type}</Table.Td>
      <Table.Td>{isProductInSku(sku.is_pro)}</Table.Td>
      <Table.Td>{isProductInSku(sku.is_till_rx)}</Table.Td>
      <Table.Td>{isProductInSku(sku.pressure_panel)}</Table.Td>
      <Table.Td>{isProductInSku(sku.performance_panel)}</Table.Td>
      <Table.Td>{isProductInSku(sku.nutrient_panel)}</Table.Td>
      <Table.Td>{isProductInSku(sku.nitrate_panel)}</Table.Td>
      <Table.Td>{sku.analysis_sku_name}</Table.Td>
    </Table.Tr>
  ));

  return (
    <Stack>
      <AddSkuModal
        opened={opened}
        onClose={close}
        onSubmit={mutation.mutate}
        allSkus={skuQuery.data}
      />
      <Group justify="flex-end">
        <Button variant="outline" onClick={open}>
          Add SKU
        </Button>
      </Group>

      {skuQuery.data?.length ? (
        <Table withTableBorder withColumnBorders>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Name</Table.Th>
              <Table.Th>Name (pt)</Table.Th>
              <Table.Th>Netsuite Name</Table.Th>
              <Table.Th>Type</Table.Th>
              <Table.Th>Pro</Table.Th>
              <Table.Th>Till Rx</Table.Th>
              <Table.Th>Pressure Panel</Table.Th>
              <Table.Th>Performance Panel</Table.Th>
              <Table.Th>Nutrient Panel</Table.Th>
              <Table.Th>Nitrate Panel</Table.Th>
              <Table.Th>Analysis SKU (sampling)</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      ) : (
        <Center h="40vh">
          <Text fw={FONT_WEIGHT_BOLD}>No SKUs exist yet..</Text>
        </Center>
      )}
    </Stack>
  );
};

export default SkuTable;
