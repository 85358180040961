import React from 'react';
import { Divider, Group, List, Stack, Text } from '@mantine/core';
import { NO_DATA, riskLevels, riskToDisplay } from 'constants/fieldRisks';
import { FiChevronRight } from 'react-icons/fi';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';

import { EXTERNAL_LAB_DENOTER } from 'constants/results';
import RiskLegendSymbol from './RiskLegendSymbol';
import styles from './RiskLegendV2.module.css';

interface PropTypes {
  clickCropSideBar: (crop: string) => void;
  highlighted: string;
  crops: string[];
  showExternalLabItem: boolean;
}

const RiskLegend = ({ clickCropSideBar, highlighted, crops, showExternalLabItem }: PropTypes) => {
  const language = useBroswerLanguage();

  return (
    <Stack className={styles.Legend}>
      <div>
        <Text fw={500} mb="xs">
          {getString('legend', language)}
        </Text>
        <List spacing="0.2rem" size="xs" center>
          {riskLevels.map((risk) => {
            if (risk === NO_DATA) {
              return null;
            }
            return (
              <List.Item key={risk} icon={<RiskLegendSymbol risk={riskToDisplay[risk]} />}>
                {getString(riskToDisplay[risk], language) || getString('noData', language)}
              </List.Item>
            );
          })}
          {showExternalLabItem && (
            <>
              <Divider my="0.25rem" />
              <List.Item
                fs="italic"
                icon={<RiskLegendSymbol content={EXTERNAL_LAB_DENOTER} risk="" />}
              >
                {getString('externalLabResults', language)}
              </List.Item>
            </>
          )}
        </List>
      </div>
      {crops.map((crop) => (
        <Group key={crop} gap={0}>
          <Text
            key={crop}
            size="md"
            fw={highlighted === crop ? 500 : 0}
            c={highlighted !== crop ? 'dimmed' : undefined}
            onClick={() => clickCropSideBar(crop)}
            className={styles.Crop}
          >
            {getString(crop, language)}
          </Text>
          {highlighted === crop ? <FiChevronRight /> : ''}
        </Group>
      ))}
    </Stack>
  );
};

export default RiskLegend;
