import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getOperation, setActiveOperation } from 'store/operation/thunks';
import { getFieldGeometries, getFieldGeometry } from 'store/fields/thunks';
import { getLastSamplingPlanWithResults, getSortedPlansWithResults } from 'util/samplePlan';
import { GeoJSON } from 'geojson';
import getSamplesForField from 'store/samples/thunks';
import { SampleType, SamplingPlanType } from 'store/fields/types';
import { RootState } from 'store';
import { getAnalyticById } from 'store/analytics/thunks';
import { filterSamplesAndAddRisk } from 'util/sample';
import { fetchFieldGeometry } from 'store/fields/requests';
import showToast, { type ToastType } from 'actions/toastActions';
import { capitalizeEveryWord } from 'util/stringUtils';
import { analyticPreferenceList, getAnalytic } from 'util/results';
import { getSamplePlanRecommendations } from 'store/recommendations/thunks';
import { Box, Center, Loader, Text } from '@mantine/core';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import Map from './Map';
import PanelContainer from './Panels/Container';
import styles from './Container.module.css';
import { COMPACTION_ID } from 'constants/results';
import CompactionMap from './CompactionMap';

const FieldResultsContainer = () => {
  const { operationId, fieldId, planId, analyticId } = useParams();
  const numericOperationId = Number(operationId);
  const numericFieldId = Number(fieldId);
  const numericPlanId = Number(planId);
  const numericAnalyticId = Number(analyticId);
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [samplingPlan, setSamplingPlan] = useState<SamplingPlanType>();
  const [selectedField, setSelectedField] = useState<number | undefined>(numericFieldId);
  const {
    analytic,
    operation,
    fieldGeometry,
    samples,
    isFieldsLoading,
    midwestBenchmark,
    analyticCategories,
    analytics,
    allFieldGeometries,
    recommendations,
  } = useSelector((state: RootState) => ({
    operation: state.operations.operationsById[numericOperationId],
    analytic: state.analytics.byId[numericAnalyticId],
    fieldGeometry: state.fieldGeometry.geometries[numericFieldId],
    allFieldGeometries: state.fieldGeometry.geometries,
    samples: state.samples.samples[numericFieldId],
    isFieldsLoading: state.fieldGeometry.isFetchingList.length > 0,
    midwestBenchmark: state.analytics.midwestBenchmarksById[numericAnalyticId],
    analytics: state.analytics.analytics,
    analyticCategories: state.analytics.analyticCategories,
    recommendations: state.recommendations.byPlanId[numericPlanId],
  }));

  const preferredAnalytics = analyticPreferenceList(operation, analytics);

  useEffect(() => {
    if (numericPlanId) {
      dispatch(getSamplePlanRecommendations(numericPlanId));
    }
  }, [dispatch, numericPlanId]);

  const planAnalytic = getAnalytic(samplingPlan, analytic?.category, analytic?.id);

  useEffect(() => {
    if (!operation) {
      dispatch(getOperation(numericOperationId));
    } else {
      dispatch(setActiveOperation(operation.id));
    }
  }, [dispatch, numericOperationId, operation]);

  useEffect(() => {
    if (!fieldGeometry) {
      dispatch(getFieldGeometry(numericFieldId));
    }
  }, [fieldGeometry, dispatch, numericFieldId]);

  useEffect(() => {
    if (!analytic) {
      dispatch(getAnalyticById(numericAnalyticId));
    }
  }, [analytic, dispatch, numericAnalyticId]);

  const setToastMessage = useCallback(
    (message: string, type?: ToastType, seconds = 3000) => {
      showToast(message, type, seconds);
    },
    [dispatch],
  );

  const sortedPlans = useMemo(() => {
    if (fieldGeometry?.features[0].properties) {
      return getSortedPlansWithResults(fieldGeometry.features[0].properties, analyticCategories);
    }
    return [];
  }, [fieldGeometry, analyticCategories]);

  useEffect(() => {
    if (sortedPlans.length && numericPlanId) {
      const theSamplingPlan = sortedPlans.find((plan) => plan.id === numericPlanId);
      if (theSamplingPlan) {
        setSamplingPlan(theSamplingPlan);
      }
    }
  }, [numericPlanId, sortedPlans]);

  useEffect(() => {
    if (!samples) {
      dispatch(getSamplesForField(numericFieldId, analytics));
    }
  }, [numericFieldId, samples, analytics]);

  useEffect(() => {
    if (operation && samples && samplingPlan) {
      const newFieldIds = operation.fields.map((field) => field.id);
      const alreadyExistIds = Object.keys(allFieldGeometries).map((str) => parseInt(str, 10));
      const neededFieldIds = newFieldIds.filter((nfid) => !alreadyExistIds.includes(nfid));
      if (neededFieldIds.length) {
        dispatch(getFieldGeometries(neededFieldIds));
      }
    }
  }, [dispatch, operation, allFieldGeometries, samples, samplingPlan]);

  const filteredSamples = useMemo(
    () => filterSamplesAndAddRisk(samples, samplingPlan, analytic, fieldGeometry),
    [samples, samplingPlan, analytic, fieldGeometry],
  ) as GeoJSON.Feature<GeoJSON.Geometry, SampleType>[];

  const setNewField = async (fId: string) => {
    const newField = operation.fields.find((field) => field.id === Number(fId));
    if (newField) {
      setSelectedField(newField.id);
      const field = allFieldGeometries[newField.id]
        ? allFieldGeometries[newField.id]
        : await fetchFieldGeometry(newField.id);
      const plan = getLastSamplingPlanWithResults(field.features[0].properties, analyticCategories);

      if (plan) {
        navigate(`/results/analytic/${operation.id}/${plan.field_id}/${plan.id}/${analytic.id}`);
      } else {
        setSelectedField(samplingPlan?.field_id);
        setToastMessage(
          `${capitalizeEveryWord(newField.name)} does not have completed results.`,
          'error',
          5000,
        );
      }
    }
  };

  if (!fieldGeometry || !samplingPlan || !operation || !analytic || !samples) {
    return (
      <Center h="95vh">
        <Loader />
      </Center>
    );
  }

  const getMapContent = () => {
    if (!planAnalytic) {
      return (
        <Box className={styles.NoData}>
          <Center h={0}>
            <Text fw={700}>{getString('noDataForFieldAnalytic', language)}</Text>
          </Center>
        </Box>
      );
    }

    if (analytic.id === COMPACTION_ID) {
      return (
        <CompactionMap
          activeAnalytic={analytic}
          field={fieldGeometry}
          samplingPlan={samplingPlan}
        />
      );
    }
    return (
      <Map
        activeAnalytic={analytic}
        samplingPlan={samplingPlan}
        field={fieldGeometry}
        samples={filteredSamples}
        midwestBenchmark={midwestBenchmark}
        recommendations={recommendations}
      />
    );
  };

  return (
    <Box className={styles.Wrapper}>
      <Box className={styles.MenuWrapper}>
        <Box className={styles.PanelContainerWrapper}>
          <PanelContainer
            operation={operation}
            field={fieldGeometry}
            samplingPlan={samplingPlan}
            activeAnalytic={analytic}
            // @ts-ignore
            samples={filteredSamples}
            isFieldsLoading={isFieldsLoading}
            analytics={preferredAnalytics}
            analyticCategories={analyticCategories}
            selectedField={selectedField}
            setNewField={setNewField}
            samplingPlans={sortedPlans}
            recommendations={recommendations}
          />
        </Box>
        <Box data-test-id="testtest" className={styles.MapField}>
          {getMapContent()}
        </Box>
      </Box>
    </Box>
  );
};

export default FieldResultsContainer;
