import React, { useState, Fragment } from 'react';
import {
  Table,
  Select,
  TextInput,
  Group,
  Text,
  Button,
  Stack,
  Center,
  Loader,
  Space,
  ActionIcon,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { FiPlus } from 'react-icons/fi';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { ContractSkuType, ContractV2Type, SkuType } from 'store/pricing/types';
import { LIGHT_GREY_BG } from 'constants/colors';
import AddDefaultContractModal from './AddDefaultContractModal';
import { useQuery, useQueryClient, useMutation, UseQueryResult } from '@tanstack/react-query';
import { pricingQueryKeys } from 'util/queryKeys';
import {
  addSkuToContract,
  createDefaultContract,
  getDefaultContracts,
} from 'store/pricing/requests';
import { formatCalendarMonthDayYear } from 'util/date';
import AddContractSkuModal from './AddContractSkuModal';

interface Props {
  skuQuery: UseQueryResult<SkuType[], Error>;
}

const PricingTable = ({ skuQuery }: Props) => {
  const language = useBroswerLanguage();
  const [displayedContract, setDisplayedContract] = useState<ContractV2Type | null>(null);
  const [modalDefaults, setModalDefaults] = useState<{ skuId: number; priceType: string } | null>(
    null,
  );
  const [openedDefaultContract, { open: openDefaultContract, close: closeDefaultContract }] =
    useDisclosure();
  const [openedSku, { open: openSku, close: closeSku }] = useDisclosure();

  const queryClient = useQueryClient();
  const queryKey = pricingQueryKeys.defaultContract;

  const defaultContractQuery = useQuery({
    queryKey,
    queryFn: async () => {
      const res = await getDefaultContracts();
      setDisplayedContract(res.find((contract) => contract.is_current));
      return res;
    },
  });

  const defaultContractMutation = useMutation({
    mutationFn: (contract: Partial<ContractV2Type>) => createDefaultContract(contract),
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
  });

  const contractSkuMutation = useMutation({
    mutationFn: ({
      contractId,
      contractSku,
    }: {
      contractId: number;
      contractSku: Partial<ContractSkuType>;
    }) => addSkuToContract(contractId, contractSku),
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
  });

  const getSkuInfo = (skuId: string) => {
    return skuQuery.data?.find((sku) => sku.id === Number(skuId));
  };

  const closeContractSkuModal = () => {
    setModalDefaults(null);
    closeSku();
  };

  const openContractSku = (skuId: string) => {
    const priceDetails = displayedContract?.skus[skuId] || [];
    setModalDefaults({ skuId: Number(skuId), priceType: priceDetails[0]?.price_type });
    openSku();
  };

  const getInnerRows = (skuId: string) => {
    const priceDetails = displayedContract?.skus[skuId] || [];
    return priceDetails.map((item) => (
      <Table.Tr key={item.id}>
        <Table.Td />
        <Table.Td>{item.price_type}</Table.Td>
        <Table.Td>
          <TextInput leftSection="$" value={item.agency_price} disabled />
        </Table.Td>
        <Table.Td>
          <TextInput leftSection="$" value={item.list_price} disabled />
        </Table.Td>
        <Table.Td>
          <TextInput value={item.min_density} disabled />
        </Table.Td>
        <Table.Td>
          <TextInput value={item.max_density} disabled />
        </Table.Td>
      </Table.Tr>
    ));
  };

  if (defaultContractQuery.isPending) {
    return (
      <Center h="40vh">
        <Loader />
      </Center>
    );
  }

  return (
    <Stack>
      <AddDefaultContractModal
        opened={openedDefaultContract}
        onClose={closeDefaultContract}
        onSubmit={defaultContractMutation.mutate}
      />
      <AddContractSkuModal
        opened={openedSku}
        onClose={closeContractSkuModal}
        skuOptions={skuQuery.data || []}
        modalDefaults={modalDefaults}
        onSubmit={(contractSku) =>
          displayedContract &&
          contractSkuMutation.mutate({ contractId: displayedContract.id, contractSku })
        }
      />
      <Group justify="flex-end" align="flex-end">
        <Select
          label="Select Contract"
          data={defaultContractQuery.data.map((contract) => ({
            id: String(contract.id),
            label: formatCalendarMonthDayYear(contract.signed_at),
            value: String(contract.id),
          }))}
          value={String(displayedContract?.id)}
          onChange={(val) =>
            val && setDisplayedContract(defaultContractQuery.data.find((c) => c.id === Number(val)))
          }
        />
        <Button variant="outline" onClick={openDefaultContract}>
          Create Default Contract
        </Button>
        <Button>Save</Button>
      </Group>
      <Table withTableBorder withColumnBorders>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>{getString('productName', language)}</Table.Th>
            <Table.Th>{getString('priceType', language)}</Table.Th>
            <Table.Th>{getString('agencyPrice', language)}</Table.Th>
            <Table.Th>{getString('listPrice', language)}</Table.Th>
            <Table.Th>{getString('minDensity', language)}</Table.Th>
            <Table.Th>{getString('maxDensity', language)}</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {Object.keys(displayedContract?.skus || {})?.map((skuId) => (
            <Fragment key={skuId}>
              <Table.Tr bg={LIGHT_GREY_BG}>
                <Table.Td>
                  <Text size="sm">{getSkuInfo(skuId)?.name}</Text>
                </Table.Td>
                <Table.Td />
                <Table.Td />
                <Table.Td />
                <Table.Td />
                <Table.Td />
                <Table.Td>
                  <ActionIcon onClick={() => openContractSku(skuId)}>
                    <FiPlus />
                  </ActionIcon>
                </Table.Td>
              </Table.Tr>
              {getInnerRows(skuId)}
            </Fragment>
          ))}
        </Table.Tbody>
      </Table>
      <Space h="lg" />
      <Group justify="center">
        <Button w="10rem" onClick={openSku}>
          Add SKU
        </Button>
      </Group>
    </Stack>
  );
};

export default PricingTable;
