import ForgotPasswordContainer from 'apps/Authentication/ForgotPassword/Container';
import LoginContainer from 'apps/Authentication/Login/Container';
import LogoutContainer from 'apps/Authentication/Logout/Container';
import PasswordResetContainer from 'apps/Authentication/PasswordReset/Container';
import RegistrationContainer from 'apps/Authentication/Registration/Container';
import TextOptOut from 'apps/Authentication/TextOptOut/Container';
import CreateOperationContainer from 'apps/Operations/CreateOperation/Container';
import EditOperationContainer from 'apps/Operations/EditOperation/Container';

import IngestSummaryContainer from 'apps/Operations/FileUpload/IngestSummary/Container';
import IngestHistoryContainer from 'apps/Operations/FileUpload/IngestHistory/Container';

import SearchBarContainer from 'apps/Operations/SearchBar/Container';
import MultipleOperationsContainer from 'apps/Operations/MultipleOperations/Container';
import OperationsContainer from 'apps/Operations/Dashboard/Container';
import WelcomeContainer from 'apps/Operations/Welcome/Container';

import PaymentOpenDetailsContainer from 'apps/Payments/OpenDetails/Container';
import PaymentApprovedDetailsContainer from 'apps/Payments/ApprovedDetails/Container';
import PaymentOverviewContainer from 'apps/Payments/Overview/Container';
import QuickbooksCallback from 'apps/Payments/Quickbooks/Container';

import FieldBoundaryContainer from 'apps/Orders/FieldBoundary/Container';
import OrdersListContainer from 'apps/Orders/FieldList/Container';
import SampleStatusContainer from 'apps/Orders/SampleStatus/Container';

import FieldResultsContainer from 'apps/Results/Field/Container';
import FieldPrescriptionsContainer from 'apps/Results/Prescriptions/Container';

import SettingsContainer from 'apps/Settings/Container';

import EditUserContainer from 'apps/Users/EditUser/Container';
import UsersContainer from 'apps/Users/UserDashboard/Container';
import SamplePricingContainer from 'apps/SamplePricing/Container';

import ManageAgenciesContainer from 'apps/Agencies/ManageAgencies/Container';
import CreatePlateContainer from 'apps/Lab/Plates/Create/Container';
import PlateDetailsContainer from 'apps/Lab/Plates/Details/Container';
import PlateOverviewContainer from 'apps/Lab/Plates/Overview/Container';

import BatchesOverviewContainer from 'apps/Lab/Batches/Overview/Container';
import CreateBatchContainer from 'apps/Lab/Batches/Create/Container';
import BatchDetailsContainer from 'apps/Lab/Batches/Details/Container';

import ControlsContainer from 'apps/Lab/Controls/Container';
import NaptOverview from 'apps/Lab/Napt/Overview/Container';

import AttributesContainer from 'apps/Lab/Attributes/Container';
import ProtocolsContainer from 'apps/Lab/Protocols/Container';

import IndexSetOverviewContainer from 'apps/Lab/IndexSets/Overview/Container';
import IndexSetDetailContainer from 'apps/Lab/IndexSets/Details/Container';

import FeatureFlagContainer from 'apps/Product/FeatureFlags/Detail/Container';
import FeatureFlagsContainer from 'apps/Product/FeatureFlags/Overview/Container';
import SampleOverviewController from 'apps/Lab/Samples/Overview/Container';
import SampleDetailsContainer from 'apps/Lab/Samples/Details/Container';

import ProcessingOverviewContainer from 'apps/Lab/Processing/Container';

import ListAnalyticsContainer from 'apps/Product/Analytics/Container';
import EditAnalyticContainer from 'apps/Product/EditAnalytics/Container';
import ListRecommendationSetsContainer from 'apps/Product/RecommendationSets/Container';
import EditRecommendationSetContainer from 'apps/Product/EditRecommendationSets/Container';
import CommercialTerritoryContainer from 'apps/Product/CommercialTerritory/Container';

import ManageAgencyContainer from 'apps/Agencies/ManageAgency/Container';

import ZoneAnalysisV2Container from 'apps/ZoneAnalysisV2/Container';
import ZoneAnalysisV3Container from 'apps/ZoneAnalysisV3/Container';
import FieldResultsSummaryContainer from 'apps/Results/FieldSummary/Container';

import ResultsSummaryV2Container from 'apps/Results/OverviewSummaryV2/Container';
import ShippingLabelContainer from 'apps/Shipping/ShippingLabel/Container';

import LabShippingContainer from 'apps/Lab/Shipping/Overview/Container';
import LabShippingDetailsContainer from 'apps/Lab/Shipping/Details/Container';

import SamplePlanTrackingContainer from 'apps/SamplePlanTracking/Container';
import { SamplingHistoryContainer } from 'apps/SamplingHistory/Container';
import DataReviewContainer from 'apps/DataReview/Container';

import { OrderHistoryContainer } from 'apps/OrderHistory/Container';

import { LabAppNavBarLayout, Layout, NavBarLayout } from 'common';
import RegisterUser from 'apps/Operations/RegisterUser';
import RiskEstimateContainer from 'apps/SalesFlow/Container';
import AgLeaderCallbackContainer from 'apps/Operations/FileUpload/AgLeaderCallBackContainer';

import HeatMapContainer from 'apps/HeatMap/Container';

import ReleaseDashboard from 'apps/Product/ReleaseDashboard/Container';
import CropPlanningContainer from 'apps/Planning/Container';
import ProductContractContainer from 'apps/Product/Pricing/Container';

import TestEOCollections from 'apps/Product/TestData/TestEOCollections';
import CatalogListContainer from 'apps/Planning/ManageCatalog/Overview/Container';
import EditCatalogContainer from 'apps/Planning/ManageCatalog/Detail/Container';
import { PlatesMergeContainer } from 'apps/Lab/Plates/Merge/Container';
import HarvestContainer from 'apps/Harvest/Container';

export default [
  {
    component: RiskEstimateContainer,
    exact: true,
    layout: NavBarLayout,
    path: '/risk-estimate',
    isAgentRoute: true,
    isAdminRoute: true,
    isSuperAdminRoute: true,
    isAgencyAdminRoute: true,
  },
  {
    component: OperationsContainer,
    exact: true,
    layout: NavBarLayout,
    path: '/',
  },
  {
    component: ForgotPasswordContainer,
    exact: false,
    layout: Layout,
    path: '/auth/forgot',
  },
  {
    component: LoginContainer,
    exact: false,
    layout: Layout,
    path: '/auth/login',
  },
  {
    component: PasswordResetContainer,
    exact: false,
    layout: Layout,
    path: '/auth/reset',
  },
  {
    component: RegistrationContainer,
    exact: false,
    layout: NavBarLayout,
    path: '/auth/register',
  },
  {
    component: TextOptOut,
    exact: true,
    layout: Layout,
    path: '/auth/text/opt-out',
  },
  {
    component: ManageAgencyContainer,
    exact: false,
    layout: NavBarLayout,
    path: '/agencies/:agencyId',
  },
  {
    component: OrdersListContainer,
    exact: false,
    layout: NavBarLayout,
    path: '/orders/:operationId',
  },
  {
    component: SampleStatusContainer,
    exact: false,
    layout: NavBarLayout,
    path: '/view-sample-status/:operationId/:fieldId',
  },
  {
    component: SampleStatusContainer,
    exact: false,
    layout: NavBarLayout,
    path: '/view-sample-status/:operationId/:fieldId/:planId',
  },
  {
    component: FieldBoundaryContainer,
    exact: false,
    layout: NavBarLayout,
    path: '/field-boundary/:operationId/:fieldId',
  },
  {
    component: ResultsSummaryV2Container,
    exact: true,
    layout: NavBarLayout,
    path: '/results/operation/:operationId',
  },
  {
    component: ResultsSummaryV2Container,
    exact: true,
    layout: NavBarLayout,
    path: '/results/operation/:operationId/:analysis',
  },
  {
    component: FieldResultsSummaryContainer,
    exact: true,
    layout: NavBarLayout,
    path: '/results/field/:operationId/:fieldId/:planId',
  },
  {
    component: FieldResultsSummaryContainer,
    exact: true,
    layout: NavBarLayout,
    path: '/results/field/:operationId/:fieldId/:planId/:analysis',
  },
  {
    component: FieldResultsContainer,
    exact: true,
    layout: NavBarLayout,
    path: '/results/analytic/:operationId/:fieldId/:planId/:analyticId',
  },
  {
    component: FieldPrescriptionsContainer,
    exact: true,
    layout: NavBarLayout,
    path: '/results/rx/:operationId/:fieldId/:prescriptionId',
  },
  {
    component: LogoutContainer,
    exact: false,
    layout: NavBarLayout,
    path: '/logout',
  },
  {
    component: CreateOperationContainer,
    exact: false,
    layout: NavBarLayout,
    path: '/operations/create',
  },
  {
    component: RegisterUser,
    exact: false,
    layout: NavBarLayout,
    path: '/operations/register',
    isAgentRoute: true,
    isAdminRoute: true,
    isSuperAdminRoute: true,
    isAgencyAdminRoute: true,
  },
  {
    component: EditOperationContainer,
    exact: false,
    layout: NavBarLayout,
    path: '/operations/:id/edit',
  },
  {
    component: MultipleOperationsContainer,
    exact: false,
    layout: NavBarLayout,
    path: '/operations/manage',
  },
  {
    component: MultipleOperationsContainer,
    exact: false,
    layout: NavBarLayout,
    path: '/operations/manage/all',
  },
  {
    component: IngestHistoryContainer,
    exact: true,
    layout: NavBarLayout,
    path: '/operations/:operationId/ingest_history',
  },
  {
    component: AgLeaderCallbackContainer,
    exact: true,
    layout: NavBarLayout,
    path: '/agleader_callback',
  },
  {
    component: IngestHistoryContainer,
    exact: true,
    layout: NavBarLayout,
    path: '/connected-accounts',
  },
  {
    component: IngestHistoryContainer,
    exact: true,
    layout: NavBarLayout,
    path: '/connected-accounts/:accountType',
  },
  {
    component: IngestHistoryContainer,
    exact: true,
    layout: NavBarLayout,
    path: '/connected-accounts/:accountType/:operationId',
  },
  {
    component: IngestSummaryContainer,
    exact: true,
    layout: NavBarLayout,
    path: '/operations/:id/ingest_summary/:runId',
  },
  {
    component: SearchBarContainer,
    exact: true,
    layout: NavBarLayout,
    path: '/operations',
  },
  {
    component: ShippingLabelContainer,
    exact: true,
    layout: NavBarLayout,
    path: '/shipping/new',
  },
  {
    component: PaymentOpenDetailsContainer,
    exact: false,
    layout: NavBarLayout,
    path: '/payments/open/details/:userId',
  },
  {
    component: PaymentApprovedDetailsContainer,
    exact: false,
    layout: NavBarLayout,
    path: '/payments/approved/details/:paymentUuid',
  },
  {
    component: PaymentOverviewContainer,
    exact: true,
    layout: NavBarLayout,
    path: '/payments',
  },
  {
    component: PaymentOverviewContainer,
    exact: true,
    layout: NavBarLayout,
    path: '/payments/:status',
  },
  {
    component: QuickbooksCallback,
    exact: true,
    layout: NavBarLayout,
    path: '/quickbooks/callback',
  },
  {
    component: SamplingHistoryContainer,
    exact: true,
    layout: NavBarLayout,
    path: '/sampling-history',
  },
  {
    component: SamplingHistoryContainer,
    exact: true,
    layout: NavBarLayout,
    path: '/sampling-history/:year',
  },
  {
    component: DataReviewContainer,
    exact: true,
    layout: NavBarLayout,
    path: '/data-review',
  },
  {
    component: OrderHistoryContainer,
    exact: true,
    layout: NavBarLayout,
    path: '/order-history',
  },
  {
    component: OrderHistoryContainer,
    exact: true,
    layout: NavBarLayout,
    path: '/order-history/:year',
  },
  {
    component: ZoneAnalysisV2Container,
    exact: false,
    layout: NavBarLayout,
    path: '/zone-analysis/:operationId/:fieldId',
  },
  {
    component: ZoneAnalysisV2Container,
    exact: false,
    layout: NavBarLayout,
    path: `/zone-analysis/:operationId/:fieldId/:planId`,
  },
  {
    component: ZoneAnalysisV3Container,
    exact: false,
    layout: NavBarLayout,
    path: '/zone-analysis-v3/:operationId/:fieldId',
  },
  {
    component: ZoneAnalysisV3Container,
    exact: false,
    layout: NavBarLayout,
    path: `/zone-analysis-v3/:operationId/:fieldId/:planId`,
  },
  {
    component: SettingsContainer,
    exact: false,
    layout: NavBarLayout,
    path: '/users/:userId',
  },
  {
    component: SettingsContainer,
    exact: false,
    layout: NavBarLayout,
    path: '/settings',
  },
  {
    component: UsersContainer,
    exact: true,
    layout: NavBarLayout,
    path: '/users',
  },
  {
    component: SamplePricingContainer,
    exact: true,
    layout: NavBarLayout,
    path: '/sample-pricing',
    isSuperAdminRoute: true,
  },
  {
    component: SamplePlanTrackingContainer,
    exact: true,
    layout: NavBarLayout,
    path: '/plan-tracking',
    isSuperAdminRoute: true,
    isAdminRoute: true,
  },
  {
    component: ManageAgenciesContainer,
    exact: true,
    layout: NavBarLayout,
    path: '/agencies',
  },
  {
    component: EditUserContainer,
    exact: false,
    layout: NavBarLayout,
    path: '/users/:id',
  },
  {
    component: WelcomeContainer,
    exact: false,
    layout: NavBarLayout,
    path: '/welcome',
  },
  {
    component: ProcessingOverviewContainer,
    exact: true,
    layout: NavBarLayout,
    path: '/lab',
  },
  {
    component: FeatureFlagContainer,
    exact: true,
    layout: NavBarLayout,
    path: '/product/feature_flag/:id',
  },
  {
    component: FeatureFlagsContainer,
    exact: true,
    layout: NavBarLayout,
    path: '/product/feature_flags',
  },
  {
    component: ListAnalyticsContainer,
    exact: true,
    layout: NavBarLayout,
    path: '/product/analytics',
  },
  {
    component: EditAnalyticContainer,
    exact: true,
    layout: NavBarLayout,
    path: '/product/analytics/create',
  },
  {
    component: EditAnalyticContainer,
    exact: true,
    layout: NavBarLayout,
    path: '/product/analytics/:id',
  },
  {
    component: ListRecommendationSetsContainer,
    exact: true,
    layout: NavBarLayout,
    path: '/product/recommendation_sets',
  },
  {
    component: EditRecommendationSetContainer,
    exact: true,
    layout: NavBarLayout,
    path: '/product/recommendation_sets/create',
  },
  {
    component: EditRecommendationSetContainer,
    exact: true,
    layout: NavBarLayout,
    path: '/product/recommendation_sets/:id',
  },
  {
    component: CommercialTerritoryContainer,
    exact: true,
    layout: NavBarLayout,
    path: '/product/commercial_territory',
  },
  {
    component: TestEOCollections,
    exact: true,
    layout: NavBarLayout,
    path: '/product/test_date/eo',
  },
  {
    component: ProductContractContainer,
    exact: true,
    layout: NavBarLayout,
    path: '/product/pricing/:viewId',
  },
  {
    component: CreatePlateContainer,
    exact: true,
    layout: LabAppNavBarLayout,
    path: '/lab/plates/create',
  },
  {
    component: CreatePlateContainer,
    exact: true,
    layout: LabAppNavBarLayout,
    path: '/lab/plates/create/:plateType/*',
  },
  {
    component: PlateDetailsContainer,
    exact: false,
    layout: LabAppNavBarLayout,
    path: '/lab/plates/details/:plateId',
  },
  {
    component: PlatesMergeContainer,
    exact: false,
    layout: LabAppNavBarLayout,
    path: '/lab/plates/merge/:plateId',
  },
  {
    component: PlateOverviewContainer,
    exact: true,
    layout: LabAppNavBarLayout,
    path: '/lab/plates',
  },
  {
    component: CreateBatchContainer,
    exact: true,
    layout: LabAppNavBarLayout,
    path: '/lab/batches/create',
  },
  {
    component: CreateBatchContainer,
    exact: true,
    layout: LabAppNavBarLayout,
    path: '/lab/batches/create/:batchType/*',
  },
  {
    component: BatchDetailsContainer,
    exact: true,
    layout: LabAppNavBarLayout,
    path: '/lab/batches/details/:id',
  },
  {
    component: BatchesOverviewContainer,
    exact: true,
    layout: LabAppNavBarLayout,
    path: '/lab/batches',
  },
  {
    component: IndexSetOverviewContainer,
    exact: true,
    layout: LabAppNavBarLayout,
    path: '/lab/index_sets',
  },
  {
    component: IndexSetDetailContainer,
    exact: false,
    layout: LabAppNavBarLayout,
    path: '/lab/index_sets/details/:indexSetName',
  },
  {
    component: NaptOverview,
    exact: true,
    layout: LabAppNavBarLayout,
    path: '/lab/napt_controls',
  },
  {
    component: ControlsContainer,
    exact: true,
    layout: LabAppNavBarLayout,
    path: '/lab/control_sets',
  },
  {
    component: AttributesContainer,
    exact: true,
    layout: LabAppNavBarLayout,
    path: '/lab/rd_attributes',
  },
  {
    component: ProtocolsContainer,
    exact: true,
    layout: LabAppNavBarLayout,
    path: '/lab/rd_protocols',
  },
  {
    component: SampleOverviewController,
    exact: true,
    layout: LabAppNavBarLayout,
    path: '/lab/samples',
  },
  {
    component: SampleDetailsContainer,
    exact: true,
    layout: LabAppNavBarLayout,
    path: '/lab/samples/:barcode',
  },
  {
    component: LabShippingContainer,
    exact: true,
    layout: LabAppNavBarLayout,
    path: '/lab/shipping',
  },
  {
    component: LabShippingDetailsContainer,
    exact: true,
    layout: LabAppNavBarLayout,
    path: '/lab/shipping/create',
  },
  {
    component: LabShippingDetailsContainer,
    exact: true,
    layout: LabAppNavBarLayout,
    path: '/lab/shipping/details/:groupId',
  },
  {
    component: ProcessingOverviewContainer,
    exact: true,
    layout: LabAppNavBarLayout,
    path: '/lab/:analysis',
  },
  {
    component: ProcessingOverviewContainer,
    exact: true,
    layout: LabAppNavBarLayout,
    path: '/lab/:analysis/:view',
  },
  {
    component: HeatMapContainer,
    exact: true,
    layout: NavBarLayout,
    path: '/risk-map/',
  },
  {
    component: HeatMapContainer,
    exact: true,
    layout: NavBarLayout,
    path: '/risk-map/my-accounts',
  },
  {
    component: HeatMapContainer,
    exact: true,
    layout: NavBarLayout,
    path: '/risk-map/my-accounts/:cropYear',
  },
  {
    component: HeatMapContainer,
    exact: true,
    layout: NavBarLayout,
    path: '/risk-map/demo/:cropYear',
  },
  {
    component: HeatMapContainer,
    exact: true,
    layout: NavBarLayout,
    path: '/risk-map/:agencyId/:cropYear',
  },
  {
    component: HeatMapContainer,
    exact: true,
    layout: NavBarLayout,
    path: '/risk-map/:agencyId',
  },
  {
    component: ReleaseDashboard,
    exact: true,
    layout: NavBarLayout,
    path: '/product/release_dashboard',
  },
  {
    component: CropPlanningContainer,
    exact: false,
    layout: NavBarLayout,
    path: '/planning/:operationId/',
  },
  {
    component: CropPlanningContainer,
    exact: false,
    layout: NavBarLayout,
    path: '/planning/:operationId/:analysis',
  },
  {
    component: CatalogListContainer,
    exact: true,
    layout: NavBarLayout,
    path: '/catalogs',
  },
  {
    component: CatalogListContainer,
    exact: true,
    layout: NavBarLayout,
    path: '/catalogs/all',
  },
  {
    component: EditCatalogContainer,
    exact: true,
    layout: NavBarLayout,
    path: '/catalog/create',
  },
  {
    component: EditCatalogContainer,
    exact: true,
    layout: NavBarLayout,
    path: '/catalog/:id',
  },
  // TODO: remove after removal recs are available
  {
    component: HarvestContainer,
    exact: true,
    layout: NavBarLayout,
    path: '/field-machine-data-visualizer',
  },
];
