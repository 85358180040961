import { Flex, Skeleton } from '@mantine/core';
import React from 'react';

const TableLoadingSkeletons = () => {
  const Skeletons = (
    <Flex mt="sm" p="sm" justify="space-between" gap="xl">
      <Skeleton w="25%" height={25} />
      <Skeleton w="25%" height={25} />
      <Skeleton w="35%" height={25} />
      <Skeleton w="15%" height={25} />
    </Flex>
  );

  return (
    <>
      {Skeletons}
      {Skeletons}
      {Skeletons}
    </>
  );
};

export default TableLoadingSkeletons;
