import { ACTIVE_OPERATION_ID } from 'constants/auth';
import { getLocalStorage } from 'util/localStorage';
import { DEMO_OPERATION_ID } from 'constants/variables';
import { Agency } from 'store/agencies/types';
import { getString } from 'strings/translation';
import { CROP_PROTECTION } from './results';
import { isProduction } from 'util/request';

export const navbarLinkConfig = (
  isAdmin: boolean,
  isLab: boolean,
  isSuperAdmin: boolean,
  isAgencyAdmin: boolean,
  isAgent: boolean,
  isSampler: boolean,
  operationsCount: number,
  agencies: Agency[],
  language: string,
  showDataReview: boolean,
) => ({
  topLevelNav: [
    {
      link: isAdmin || isSuperAdmin || isAgencyAdmin ? '/operations' : '/operations/manage',
      name: getString('myAccounts', language),
      show: !isSampler,
    },
    {
      link: `/orders/${getLocalStorage(ACTIVE_OPERATION_ID) || DEMO_OPERATION_ID}`,
      name: getString('orders', language),
      show: !isSampler && operationsCount > 0,
    },
    {
      link: `/results/operation/${
        getLocalStorage(ACTIVE_OPERATION_ID) || DEMO_OPERATION_ID
      }/${CROP_PROTECTION}`,
      name: getString('results', language),
      show: !isSampler && operationsCount > 0,
    },
    {
      link: '/sampling-history',
      name: getString('history', language),
      show: isSampler,
    },
    {
      link: `/planning/${getLocalStorage(ACTIVE_OPERATION_ID) || DEMO_OPERATION_ID}`,
      name: getString('planning', language),
      show: (isAgent || isAgencyAdmin || isAdmin || isSuperAdmin) && operationsCount > 0,
    },
    {
      link: `/risk-map`,
      name: getString('riskMap', language),
      show: !isSampler,
    },
    {
      link: '/data-review',
      name: getString('dataReview', language),
      show: showDataReview,
    },
    {
      name: getString('productAdmin', language),
      show: isSuperAdmin,
      dropdownLinks: [
        {
          link: '/product/analytics',
          name: getString('analytics', language),
        },
        {
          link: '/product/feature_flags',
          name: getString('featureFlags', language),
        },
        {
          link: '/product/recommendation_sets',
          name: getString('recommendationSets', language),
        },
        {
          link: '/product/commercial_territory',
          name: getString('commercialTerritory', language),
        },
        {
          link: '/product/release_dashboard',
          name: 'Release Dashboard',
          show: !isProduction,
        },
        {
          link: '/product/test_date/eo',
          name: 'EO Collections',
          show: !isProduction,
        },
        {
          link: '/product/pricing/skus',
          name: 'Pricing / Contracts',
          show: !isProduction,
        },
      ],
    },
  ],
  dropdownLinks: [
    {
      link: '/risk-estimate',
      name: getString('createEstimate', language),
      show: isAgent || isAgencyAdmin || isAdmin || isSuperAdmin,
    },
    {
      link: '/operations/register',
      name: getString('createAccount', language),
      show: isAgent || isAgencyAdmin || isAdmin || isSuperAdmin,
    },
    {
      link: '/auth/register',
      name: getString('createUser', language),
      show: isAdmin || isSuperAdmin,
    },
    {
      link: '/users',
      name: getString('manageUsers', language),
      show: isAdmin || isSuperAdmin,
    },
    {
      link: '/agencies',
      name: getString('manageAgencies', language),
      show: isAdmin || isSuperAdmin,
    },
    {
      link: `/agencies/${agencies[0]?.id}`,
      name: getString('manageAgency', language),
      show: agencies[0] && isAgencyAdmin,
    },
    {
      link: '/catalogs',
      name: getString('manageCatalogs', language),
      show: isAdmin || isAgent || isSuperAdmin || isAgencyAdmin,
    },
    {
      link: `/results/operation/${DEMO_OPERATION_ID}/${CROP_PROTECTION}`,
      name: getString('viewDemoAccount', language),
      show: isAdmin || isAgent || isSuperAdmin || isAgencyAdmin,
    },
    {
      link: '/payments',
      name: getString('payments', language),
      show: isAdmin || isSuperAdmin,
    },
    {
      link: '/order-history',
      name: getString('orderHistory', language),
      show: isAgent || isAgencyAdmin,
    },
    {
      link: '/sampling-history',
      name: getString('samplingHistory', language),
      show: isAdmin || isAgent || isSuperAdmin || isAgencyAdmin,
    },
    {
      link: '/data-review',
      name: getString('dataReview', language),
      show: isAdmin || isSuperAdmin,
    },
    {
      link: '/shipping/new',
      name: getString('shippingLabels', language),
      show: isAdmin || isAgent || isSuperAdmin || isAgencyAdmin || isSampler,
    },
    {
      link: '/sample-pricing',
      name: getString('samplePricing', language),
      show: isSuperAdmin,
    },
    {
      link: '/plan-tracking',
      name: getString('samplePlanTracking', language),
      show: isAdmin || isSuperAdmin,
    },
    {
      link: '/settings',
      name: getString('settings', language),
      show: true,
    },
    {
      link: '/lab',
      name: getString('labApp', language),
      show: isAdmin || isSuperAdmin || isLab,
    },
    {
      link: '/logout',
      name: getString('logout', language),
      show: true,
    },
  ],
});

export const labAppLinkConfig = (isSuperAdmin: boolean, language: string) => ({
  topLevelNav: [
    {
      name: getString('prep', language),
      show: false,
      dropdownLinks: [
        {
          link: '/lab/plates',
          name: getString('plates', language),
        },
        {
          link: '/lab/batches',
          name: getString('batches', language),
        },
      ],
    },
    {
      link: '/lab/genomic',
      name: getString('genomic', language),
      show: true,
    },
    {
      link: '/lab/nutrient',
      name: getString('nutrient', language),
      show: true,
    },
    {
      link: '/lab/samples',
      name: getString('samples', language),
      show: true,
    },
    {
      link: '/lab/shipping',
      name: getString('shipping', language),
      show: true,
    },
    {
      name: 'Product',
      show: isSuperAdmin,
      dropdownLinks: [
        {
          link: '/lab/napt_controls',
          name: getString('naptControls', language),
        },
        {
          link: '/lab/control_sets',
          name: getString('controls', language),
        },
        {
          link: '/lab/index_sets',
          name: getString('indexSets', language),
        },
        {
          link: '/lab/rd_protocols',
          name: getString('rdProtocols', language),
        },
        {
          link: '/lab/rd_attributes',
          name: getString('rdAttributes', language),
        },
      ],
    },
  ],
  dropdownLinks: [
    {
      link: '/',
      name: getString('webApp', language),
      show: true,
    },
    {
      link: '/settings',
      name: getString('settings', language),
      show: true,
    },
    {
      link: '/logout',
      name: getString('logout', language),
      show: true,
    },
  ],
});

export default navbarLinkConfig;
